import { getActiveStep } from '@console/common/getActiveStep';
import { connect } from 'react-redux';
import StepHandler from '../../components/common/StepHandler';
import { steps } from '../../components/projectDraft/SideStepper';
import type { AppThunkAction, AppThunkDispatch } from '../../actions/thunkType';
import type { AppState } from '../../reducers';
import type { NavigateFunction } from 'react-router';

const pushHistoryStep = (
  navigate: NavigateFunction,
  parentStep: number,
  childStep: number
) => {
  navigate(
    `${location.pathname}?parentStep=${parentStep}&childStep=${childStep}`
  );
};

const goNextParentStep = (navigate: NavigateFunction, parentStep: number) => {
  pushHistoryStep(navigate, parentStep + 1, 0);
};

const goPreviousParentStep = (
  navigate: NavigateFunction,
  parentStep: number
) => {
  pushHistoryStep(navigate, parentStep - 1, 0);
};

const goNextChildStep = (
  navigate: NavigateFunction,
  parentStep: number,
  childStep: number
) => {
  pushHistoryStep(navigate, parentStep, childStep + 1);
};

const goPreviousChildStep = (
  navigate: NavigateFunction,
  parentStep: number,
  childStep: number
) => {
  pushHistoryStep(navigate, parentStep, childStep - 1);
};

export const goNextStep = (
  navigate: NavigateFunction,
  parentStep: number,
  childStep: number
) => {
  if (hasNextChildStep(parentStep, childStep)) {
    goNextChildStep(navigate, parentStep, childStep);
    return;
  }
  goNextParentStep(navigate, parentStep);
};

const goPreviousStep = (
  navigate: NavigateFunction,
  parentStep: number,
  childStep: number
) => {
  if (childStep > 0) {
    goPreviousChildStep(navigate, parentStep, childStep);
    return;
  }
  if (hasChildStep(parentStep - 1)) {
    pushHistoryStep(
      navigate,
      parentStep - 1,
      steps[parentStep - 1].children!.length - 1
    );
    return;
  }
  goPreviousParentStep(navigate, parentStep);
};

const handleStepNext =
  (
    handleStepNextFromParent: HandleStepNextFromParent,
    navigate: NavigateFunction
  ): AppThunkAction<void> =>
  dispatch => {
    const { parentStep, childStep } = getActiveStep();

    if (handleStepNextFromParent) {
      handleStepNextFromParent(navigate);
      return;
    }
    goNextStep(navigate, parentStep, childStep);
  };

const hasChildStep = (parent: number): boolean =>
  steps[parent].children !== undefined;

const hasNextChildStep = (parent: number, child: number): boolean => {
  if (steps[parent].children === undefined) return false;
  return child < steps[parent].children!.length - 1;
};

const handleStepBack =
  (
    HandleStepBackFromParent: HandleStepBackFromParent,
    navigate: NavigateFunction
  ): AppThunkAction<void> =>
  dispatch => {
    const { parentStep, childStep } = getActiveStep();

    if (HandleStepBackFromParent) {
      HandleStepBackFromParent(navigate);
      return;
    }
    goPreviousStep(navigate, parentStep, childStep);
  };

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (
  dispatch: AppThunkDispatch,
  ownProps: OwnProps
) => ({
  handleStepNext: (navigate: NavigateFunction) =>
    dispatch(handleStepNext(ownProps.handleStepNextFromParent, navigate)),
  handleStepBack: (navigate: NavigateFunction) =>
    dispatch(handleStepBack(ownProps.handleStepBackFromParent, navigate))
});

type OwnProps = {
  handleStepNextFromParent?: HandleStepNextFromParent;
  handleStepBackFromParent?: HandleStepBackFromParent;
};

type HandleStepNextFromParent =
  | ((navigate: NavigateFunction) => void)
  | undefined;

type HandleStepBackFromParent =
  | ((navigate: NavigateFunction) => void)
  | undefined;

export type StepHandlerPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type StepHandlerPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(StepHandler);
