import { QueryProjectUrlCode } from '@common/api/graphql/getProjectsGql';
import ComponentLoader from '@common/components/ComponentLoader';
import { getG4bMyPageProjectsUrl } from '@console/common/g4bMyPage';
import DrawerContainer from '@console/containers/project/DrawerContainer';
import FloatingButtonContainer from '@console/containers/project/FloatingButtonContainer';
import MenuIcon from '@mui/icons-material/Menu';
import { Typography, IconButton } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router';
import type {
  ProjectLayoutPropsMappedFromState,
  ProjectLayoutPropsMappedFromDispatch
} from '@console/containers/project/ProjectLayoutContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  content: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: 200,
    marginBottom: 130
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  campaignName: {
    paddingTop: theme.spacing(2)
  },
  childrenContainer: {
    paddingBottom: theme.spacing(7)
  }
});

export type ProjectLayoutPathParams = {
  urlCode?: string;
};

type ProjectLayoutProps = ProjectLayoutPropsMappedFromState &
  ProjectLayoutPropsMappedFromDispatch &
  WithStyles<typeof styles>;

const ProjectLayout: React.FC<React.PropsWithChildren<ProjectLayoutProps>> = ({
  isDrawerOpen,
  isSampleProject,
  initializeSelectedProject,
  exitProjectDetail,
  selectedProjectDetail,
  openDrawer,
  children,
  isMypageProject,
  classes
}) => {
  const params = useParams<ProjectLayoutPathParams>();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (params.urlCode) {
      initializeSelectedProject(params.urlCode)
        .then(isMypageProjectForLocation => {
          if (isMypageProjectForLocation) {
            location.href = `${getG4bMyPageProjectsUrl()}/${params.urlCode}`;
          } else {
            setIsLoaded(true);
          }
        })
        .catch(() => setIsLoaded(true));
    }
  }, []);

  useEffect(
    () => () => {
      exitProjectDetail();
    },
    []
  );

  return (
    <>
      {!isLoaded && <ComponentLoader isLoading={true} />}
      <div
        className={clsx(classes.content, {
          [classes.contentShift]: !isDrawerOpen
        })}
      >
        {isLoaded ? <DrawerContainer /> : null}
        {!isDrawerOpen ? (
          <IconButton
            className={classes.menuIcon}
            onClick={openDrawer}
            size="large"
          >
            <MenuIcon />
          </IconButton>
        ) : null}
        {isLoaded ? (
          <Fragment>
            <Typography variant="h6" className={classes.campaignName}>
              {selectedProjectDetail.name}
            </Typography>
            <div className={classes.childrenContainer}>{children}</div>
          </Fragment>
        ) : null}
        {isSampleProject || isMypageProject ? null : (
          <FloatingButtonContainer />
        )}
      </div>
    </>
  );
};

export default withStyles(styles)(ProjectLayout);
