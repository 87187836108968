import { getLocalePrice } from '@common/lib/getLocalePrice';
import Contents from '@console/components/common/contentsSelection/Contents';
import SectionTitle from '@console/components/projectDraft/common/SectionTitle';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import StepHandlerContainer from '../../../containers/projectDraft/StepHandlerContainer';
import ContentModal from '../ContentModal';
import PricesSlider from '../PricesSlider';
import type { Brand } from '../../../../../common/api/graphql/getBrandsGql';
import type {
  FixedContentSelectionPropsMappedFromState,
  FixedContentSelectionPropsMappedFromDispatch
} from '../../../containers/projectDraft/contentsSelection/FixedContentSelectionContainer';
import type { SliderPrices } from '../PricesSlider';
import type { ContentWithIsSelected } from '@console/selectors/getSelectedProjectDraftForDisplay';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

export const SLIDER_MIN = 0;
export const SLIDER_MAX = 3000;
const SLIDER_STEP = 200;
const sliderMarks = [
  {
    value: SLIDER_MIN,
    label: getLocalePrice(SLIDER_MIN)
  },
  {
    value: 1000,
    label: getLocalePrice(1000)
  },
  {
    value: 2000,
    label: getLocalePrice(2000)
  },
  {
    value: SLIDER_MAX,
    label: `${getLocalePrice(SLIDER_MAX)}以上`
  }
];

const styles = (theme: Theme): StyleRules =>
  createStyles({
    title: {
      margin: `${theme.spacing(3)} 0`
    },
    filter: {
      minWidth: 300,
      marginRight: theme.spacing(2)
    },
    errorMessage: {
      minHeight: theme.spacing(3),
      fontSize: 14
    }
  });

type FixedContentSelectionProps = FixedContentSelectionPropsMappedFromState &
  FixedContentSelectionPropsMappedFromDispatch &
  WithStyles<typeof styles>;

// TODO: 綴りの実装 & 商品のカテゴリフィルタの実装
const FixedContentSelection: React.FC<FixedContentSelectionProps> = ({
  brands,
  contents,
  contentsTotalCount,
  isContentsLoading,
  giftConfigError,
  toggleContent,
  handleStepNextFromParent,
  onChangeBrandFilter,
  onChangePriceRangeFilter,
  handleScrollToBottom,
  classes
}) => {
  const navigate = useNavigate();
  const [contentModalOpen, setContentModalOpen] = useState(false);
  const [modalContent, setModalContent] =
    useState<ContentWithIsSelected | null>(null);
  const [filterPrices, setFilterPrices] = useState<SliderPrices>({
    higherPrice: SLIDER_MAX,
    lowerPrice: SLIDER_MIN
  });

  const handleBrandChange = (selectedBrand: Brand | null) => {
    onChangeBrandFilter(selectedBrand);
  };

  const handleContentCardOnClick = (content: ContentWithIsSelected) => {
    setModalContent(content);
  };

  const handleContentModalOnSelect = (content: ContentWithIsSelected) => {
    setModalContent(null);
    toggleContent(content);
  };

  const handleContentModalOnDeselect = (content: ContentWithIsSelected) => {
    setModalContent(null);
    toggleContent(content);
  };

  const handleContentModalOnClose = () => {
    setModalContent(null);
  };

  const handleFilterPricesChange = (newFilterPrices: SliderPrices): void => {
    setFilterPrices(newFilterPrices as SliderPrices);
  };

  const handlePriceRangeChange = (priceRange: SliderPrices): void => {
    onChangePriceRangeFilter(priceRange);
  };

  useEffect(() => {
    if (!!modalContent) {
      setContentModalOpen(true);
    } else {
      setContentModalOpen(false);
    }
  }, [modalContent]);

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="flex-start"
      data-cy="fixedContentSelection"
    >
      <Typography
        id="commonError"
        className={classes.errorMessage}
        color="error"
      >
        {giftConfigError?.selectedContents}
      </Typography>
      {/* TODO: 綴りの実装 */}
      {/* <Grid item xs={12} className={classes.title}>
        <Typography variant="body1" color="primary">
          綴り枚数を選んでください
        </Typography>
      </Grid> */}

      {/* <Grid item xs={12} className={classes.input}>
        <FormControl required className={classes.formControl}>
          <InputLabel htmlFor="NumOfFixedGift">綴り枚数</InputLabel>
          <Select
            value=""
            onChange={() => console.log('HELLO')}
            name="NumOfFixedGift"
            inputProps={{
              id: 'NumOfFixedGift'
            }}
            className={classes.selectEmpty}
          >
            <MenuItem value={1}>１</MenuItem>
            <MenuItem value={2}>２</MenuItem>
            <MenuItem value={3}>３</MenuItem>
            <MenuItem value={4}>４</MenuItem>
          </Select>
          <FormHelperText>必須</FormHelperText>
        </FormControl>
      </Grid> */}

      <Grid item xs={12} className={classes.title} data-cy="pageTitle">
        <SectionTitle text="ギフトを１種類選んでください" />
      </Grid>

      <Grid item xs={12} container alignItems="center" sx={{ mb: 3 }}>
        <Autocomplete
          id="brand-search"
          options={brands}
          getOptionLabel={(option: Brand) => option.name}
          autoHighlight
          onChange={(_e, brand) => handleBrandChange(brand)}
          className={classes.filter}
          data-cy="brandSearch"
          size="small"
          renderInput={params => (
            <TextField
              {...params}
              label="ブランド"
              margin="normal"
              fullWidth
              variant="outlined"
              data-cy="brandSearchTextField"
              sx={{
                margin: 0
              }}
            />
          )}
        />
        <div className={classes.filter}>
          <PricesSlider
            sliderPrices={filterPrices}
            handleSliderPricesChange={handleFilterPricesChange}
            handleSliderPricesCommitted={handlePriceRangeChange}
            sliderMin={SLIDER_MIN}
            sliderMax={SLIDER_MAX}
            sliderStep={SLIDER_STEP}
            sliderMarks={sliderMarks}
          />
        </div>
      </Grid>

      <Contents
        contentsWithIsSelected={contents}
        contentsTotalCount={contentsTotalCount}
        isLoading={isContentsLoading}
        selectContentHandler={handleContentCardOnClick}
        scrollToBottomHandler={handleScrollToBottom}
      />

      {!!modalContent && (
        <ContentModal
          isOpen={contentModalOpen}
          onSelectHandler={() => handleContentModalOnSelect(modalContent)}
          onDeselectHandler={() => handleContentModalOnDeselect(modalContent)}
          onCloseHandler={handleContentModalOnClose}
          content={modalContent}
          isSelected={modalContent.isSelected}
        />
      )}
      <StepHandlerContainer
        handleStepNextFromParent={() => handleStepNextFromParent(navigate)}
      />
    </Grid>
  );
};

export default withStyles(styles)(FixedContentSelection);
