import { getLocalString } from '@common/lib/getLocalString';
import SectionTitle from '@console/components/projectDraft/common/SectionTitle';
import StepHandlerContainer from '@console/containers/projectDraft/StepHandlerContainer';
import ApiTableContainer from '@console/containers/projectDraft/deliveryConfig/gifteeBoxDevliveryConfig/ApiTableContainer';
import BulkTableContainer from '@console/containers/projectDraft/deliveryConfig/gifteeBoxDevliveryConfig/BulkTableContainer';
import GcpTableContainer from '@console/containers/projectDraft/deliveryConfig/gifteeBoxDevliveryConfig/GcpTableContainer';
import { Grid, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { useNavigate } from 'react-router';
import Empty from './Empty';
import type {
  GifteeBoxDeliveryConfigPropsMappedFromState,
  GifteeBoxDeliveryConfigPropsMappedFromDispatch
} from '@console/containers/projectDraft/deliveryConfig/GifteeBoxDeliveryConfigContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';
import type { ReactElement } from 'react';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    title: {
      marginTop: theme.spacing(3)
    },
    note: {
      marginTop: theme.spacing(3)
    },
    noteIcon: {
      margin: theme.spacing(5),
      color: theme.palette.info.main
    },
    noteTitle: {
      marginBottom: theme.spacing(2)
    },
    noteEnd: {
      marginTop: theme.spacing(2)
    }
  });

type GifteeBoxDeliveryConfigProps =
  GifteeBoxDeliveryConfigPropsMappedFromState &
    GifteeBoxDeliveryConfigPropsMappedFromDispatch &
    WithStyles<typeof styles>;

const GifteeBoxDeliveryConfig: React.FC<GifteeBoxDeliveryConfigProps> = ({
  giftConfig,
  isBulkSolutionSystemSelected,
  isApiSolutionSystemSelected,
  isGcpSolutionSystemSelected,
  selectedGifteeBoxTemplate,
  handleStepNextFromParent,
  classes
}) => {
  const navigate = useNavigate();
  const renderSectionTitle = (): ReactElement => {
    if (isBulkSolutionSystemSelected) {
      return (
        <SectionTitle text="単価、数量（見込み）と希望納品日を選択してください" />
      );
    }
    return <></>;
  };

  const renderBody = (): ReactElement => {
    if (!giftConfig || giftConfig.selectedGifteeBoxTemplate === null) {
      return <Empty />;
    }
    if (isBulkSolutionSystemSelected) {
      return <BulkTableContainer />;
    }
    if (isGcpSolutionSystemSelected) {
      return <GcpTableContainer />;
    }
    if (isApiSolutionSystemSelected) {
      return <ApiTableContainer />;
    }
    return <></>;
  };

  const renderNotes = (): ReactElement => {
    if (isBulkSolutionSystemSelected) {
      return (
        <>
          {selectedGifteeBoxTemplate && (
            <Typography
              variant="body1"
              color="textSecondary"
            >{`※単価は${getLocalString(
              selectedGifteeBoxTemplate.supportedPoint.minPoint
            )}以上、${getLocalString(
              selectedGifteeBoxTemplate.supportedPoint.maxPoint
            )}以下の${getLocalString(
              selectedGifteeBoxTemplate.supportedPoint.step
            )}の倍数で入力してください。`}</Typography>
          )}
        </>
      );
    }
    return <></>;
  };

  return (
    <>
      <Grid
        container
        item
        xs={12}
        className={classes.title}
        data-cy="gifteeBoxDeliveryConfigSectionTitle"
      >
        {renderSectionTitle()}
      </Grid>
      {renderBody()}
      <Grid
        container
        justifyContent="flex-start"
        data-cy="gifteeBoxDeliveryConfigNotes"
      >
        {renderNotes()}
      </Grid>
      <StepHandlerContainer
        handleStepNextFromParent={() => handleStepNextFromParent(navigate)}
      />
    </>
  );
};

export default withStyles(styles)(GifteeBoxDeliveryConfig);
