import MenuIcon from '@mui/icons-material/Menu';
import MessageIcon from '@mui/icons-material/Message';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import { Fragment } from 'react';
import { Link, useLocation, useParams } from 'react-router';
import { ProjectContents } from '../MainContents';
import type {
  DrawerPropsMappedFromState,
  DrawerPropsMappedFromDispatch
} from '../../containers/project/DrawerContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  drawer: {
    flexShrink: 0
  },
  drawerHeader: {
    marginTop: 80,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  menuIcon: {
    padding: theme.spacing()
  },
  list: {
    textDecoration: 'none',
    color: 'unset'
  },
  childListItem: {
    paddingLeft: theme.spacing(4)
  },
  linkText: {
    paddingLeft: theme.spacing(),
    whiteSpace: 'pre-wrap'
  },
  selected: {
    borderLeft: `solid 2px ${theme.palette.primary.main}`,
    marginLeft: -2,
    fontWeight: 700
  },
  divider: {
    margin: theme.spacing(2)
  },
  icon: {
    fontSize: 20,
    marginRight: theme.spacing(0.5),
    fill: theme.palette.primary.main
  }
});

type PathParams = {
  urlCode?: string;
};

type DrawerProps = DrawerPropsMappedFromState &
  DrawerPropsMappedFromDispatch &
  WithStyles<typeof styles>;

const ProjectDrawer: React.FC<DrawerProps> = ({
  isBulkProject,
  isApiProject,
  isGcpProject,
  isDrawerOpen,
  isSampleProject,
  isMypageProject,
  closeDrawer,
  isSelectedMenu,
  classes
}) => {
  const location = useLocation();
  const params = useParams<PathParams>();

  return (
    <Drawer
      variant="persistent"
      className={classes.drawer}
      anchor="left"
      open={isDrawerOpen}
      classes={{
        paper: classes.drawerPaper
      }}
      data-cy="drawer"
    >
      <div className={classes.drawerHeader}>
        <IconButton
          className={classes.menuIcon}
          onClick={closeDrawer}
          data-cy="closeButton"
          size="large"
        >
          <MenuIcon />
        </IconButton>
      </div>
      <List>
        <Link
          to={`/console/projects/${params.urlCode}/${ProjectContents.PROJECT_DETAIL}`}
          className={classes.list}
        >
          <ListItemButton data-cy="linkToDetail">
            <Typography
              variant="body2"
              className={clsx(classes.linkText, {
                [classes.selected]: isSelectedMenu(
                  location,
                  ProjectContents.PROJECT_DETAIL
                )
              })}
            >
              お申込み情報
            </Typography>
          </ListItemButton>
        </Link>
        <Link
          to={`/console/projects/${params.urlCode}/${ProjectContents.GIFT_CONFIG}`}
          className={classes.list}
        >
          <ListItemButton data-cy="linkToGiftConfig">
            <Typography
              variant="body2"
              className={clsx(classes.linkText, {
                [classes.selected]: isSelectedMenu(
                  location,
                  ProjectContents.GIFT_CONFIG
                )
              })}
            >
              利用ギフト
            </Typography>
          </ListItemButton>
        </Link>
        <Link
          to={`/console/projects/${params.urlCode}/${ProjectContents.BRAND_CHECK}`}
          className={classes.list}
        >
          <ListItemButton data-cy="linkToBrandCheck">
            <Typography
              variant="body2"
              className={clsx(classes.linkText, {
                [classes.selected]: isSelectedMenu(
                  location,
                  ProjectContents.BRAND_CHECK
                )
              })}
            >
              審査
            </Typography>
          </ListItemButton>
        </Link>
        {isBulkProject ? (
          <Link
            to={`/console/projects/${params.urlCode}/${ProjectContents.DELIVERY}`}
            className={classes.list}
          >
            <ListItemButton data-cy="linkToDelivery">
              <Typography
                variant="body2"
                className={clsx(classes.linkText, {
                  [classes.selected]: isSelectedMenu(
                    location,
                    ProjectContents.DELIVERY
                  )
                })}
              >
                納品情報
              </Typography>
            </ListItemButton>
          </Link>
        ) : null}

        <Link
          to={`/console/projects/${params.urlCode}/${ProjectContents.BILLING}`}
          className={classes.list}
        >
          <ListItemButton data-cy="linkToBilling">
            <Typography
              variant="body2"
              className={clsx(classes.linkText, {
                [classes.selected]: isSelectedMenu(
                  location,
                  ProjectContents.BILLING
                )
              })}
            >
              請求情報
            </Typography>
          </ListItemButton>
        </Link>
        {(isBulkProject || isGcpProject) && (
          <Link
            to={`/console/projects/${params.urlCode}/${ProjectContents.SELECTION_REPORT}`}
            className={classes.list}
          >
            <ListItemButton data-cy="linkToAPI">
              <Typography variant="body2" className={classes.linkText}>
                giftee Box /
                <br />
                えらべるPay選択実績
              </Typography>
            </ListItemButton>
          </Link>
        )}
        {isApiProject ? (
          <Fragment>
            <Link
              to={`/console/projects/${params.urlCode}/${ProjectContents.API_ISSUE_DETAIL}`}
              className={classes.list}
            >
              <ListItemButton data-cy="linkToAPI">
                <Typography variant="body2" className={classes.linkText}>
                  API
                </Typography>
              </ListItemButton>
            </Link>
            <Link
              to={`/console/projects/${params.urlCode}/${ProjectContents.API_ISSUE_DETAIL}`}
              className={classes.list}
            >
              <ListItemButton
                data-cy="linkToAPIToken"
                className={classes.childListItem}
              >
                <Typography
                  variant="body2"
                  className={clsx(classes.linkText, {
                    [classes.selected]: isSelectedMenu(
                      location,
                      ProjectContents.API_ISSUE_DETAIL
                    )
                  })}
                >
                  発行情報
                </Typography>
              </ListItemButton>
            </Link>
            <Link
              to={`/console/projects/${params.urlCode}/${ProjectContents.API_IP_RESTRICTION}`}
              className={classes.list}
            >
              <ListItemButton
                data-cy="linkToAPIAllowedIps"
                className={classes.childListItem}
              >
                <Typography
                  variant="body2"
                  className={clsx(classes.linkText, {
                    [classes.selected]: isSelectedMenu(
                      location,
                      ProjectContents.API_IP_RESTRICTION
                    )
                  })}
                >
                  IPアドレス制限
                </Typography>
              </ListItemButton>
            </Link>
            <Link
              to={`/console/projects/${params.urlCode}/${ProjectContents.API_ISSUE_REPORTS}`}
              className={classes.list}
            >
              <ListItemButton
                data-cy="linkToAPIIssueReports"
                className={classes.childListItem}
              >
                <Typography
                  variant="body2"
                  className={clsx(classes.linkText, {
                    [classes.selected]: isSelectedMenu(
                      location,
                      ProjectContents.API_ISSUE_REPORTS
                    )
                  })}
                >
                  発行実績
                </Typography>
              </ListItemButton>
            </Link>
            <Link
              to={`/console/projects/${params.urlCode}/${ProjectContents.SELECTION_REPORT}`}
              className={classes.list}
            >
              <ListItemButton
                data-cy="linkToAPI"
                className={classes.childListItem}
              >
                <Typography variant="body2" className={classes.linkText}>
                  giftee Box /
                  <br />
                  えらべるPay選択実績
                </Typography>
              </ListItemButton>
            </Link>
          </Fragment>
        ) : null}
        <Divider variant="middle" className={classes.divider} />
        {isSampleProject || isMypageProject ? null : (
          <Link
            to={`/console/projects/${params.urlCode}/${ProjectContents.MESSAGE_THREAD}`}
            className={classes.list}
          >
            <ListItemButton data-cy="linkToMessageThread">
              <MessageIcon className={classes.icon} />
              <Typography variant="body2" color="primary">
                担当者に問い合わせる
              </Typography>
            </ListItemButton>
          </Link>
        )}
      </List>
    </Drawer>
  );
};

export default withStyles(styles)(ProjectDrawer);
