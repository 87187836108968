import MessageThreadDialogContainer from '@console/containers/project/MessageThreadDialogContainer';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {
  Grid,
  InputBase,
  IconButton,
  Button,
  Box,
  Typography
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import Linkify from 'linkify-react';
import moment from 'moment';
import { useEffect, useState, Fragment } from 'react';
import { Link, useLocation } from 'react-router';
import UploadFileContainer from '../../containers/project/messageThread/UploadedFileContainer';
import type { UploadedFile } from '../../../../common/api/graphql/uploadFileGql';
import type {
  MessageThreadMessagesPropsMappedFromState,
  MessageThreadMessagesPropsMappedFromDispatch
} from '../../containers/project/MessageThreadMessagesContainer';
import type { ContributorBase } from '@common/api/graphql/getMessageThread';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const dateFormatForDisplay = 'YYYY-MM-DD HH:mm';

const styles = (theme: Theme): StyleRules => ({
  subContentTitle: {
    margin: `${theme.spacing(2)} 0 ${theme.spacing(1)}`,
    paddingLeft: theme.spacing(1)
  },
  messageViewportGrid: {
    height: '100%',
    margin: `0 ${theme.spacing(1)}`
  },
  messageViewportGridSample: {
    height: '85%',
    margin: `0 ${theme.spacing(1)}`
  },
  messageViewport: {
    position: 'relative',
    height: '100%'
  },
  projectName: {
    fontWeight: 'bold'
  },
  messages: {
    overflow: 'scroll',
    height: `calc(90% - ${theme.spacing(8)})`
  },
  message: {
    padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
      transition: 'all .3s'
    }
  },
  contributorName: {
    fontWeight: 'bold'
  },
  companyName: {
    marginLeft: theme.spacing(1)
  },
  postedAt: {
    marginLeft: theme.spacing(5)
  },
  uploadFileWrapper: {
    width: '50%'
  },
  messageContent: {
    marginTop: theme.spacing(0.5),
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    '& a': {
      color: theme.palette.primary.main
    }
  },
  messageForm: {
    width: '100%',
    height: 45,
    position: 'absolute',
    bottom: 50,
    padding: `0 ${theme.spacing(1)}`
  },
  iconButton: {
    padding: theme.spacing(1),
    position: 'absolute',
    bottom: 0
  },
  icon: {
    fill: theme.palette.primary.main
  },
  inputBox: {
    width: '80%',
    position: 'absolute',
    bottom: 0,
    backgroundColor: 'white',
    height: 'inherit'
  },
  submitButtonArea: {
    width: '20%',
    position: 'absolute',
    right: 0,
    height: 'inherit'
  },
  submitButton: {
    height: 'inherit',
    marginLeft: theme.spacing(1)
  },
  inputBase: {
    width: '90%',
    height: 'inherit',
    marginLeft: theme.spacing(5)
  },
  uploadedFilesViewportGrid: {
    marginLeft: theme.spacing(2),
    height: '100%'
  },
  uploadedFiles: {
    overflow: 'scroll',
    height: '90%'
  },
  fileBox: {
    marginTop: theme.spacing(2),
    cursor: 'pointer'
  },
  fileGrid: {
    padding: theme.spacing(1)
  },
  fileDetail: {
    paddingLeft: theme.spacing(1)
  },
  fileName: {
    lineHeight: '1.2'
  },
  uploadDetailGrid: {
    marginTop: theme.spacing(0.5)
  },
  uploadedBy: {
    paddingRight: theme.spacing(1)
  },
  noteForSampleProject: {
    width: '100%',
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    textAlign: 'center',
    fontWeight: 'bold'
  }
});

type MessageThreadUploadedFiles = MessageThreadUploadedFile[];
interface MessageThreadUploadedFile {
  uploadedFile: UploadedFile;
  contributorName: ContributorBase['name'];
  postedAt: moment.Moment;
}

type MessageThreadProps = MessageThreadMessagesPropsMappedFromState &
  MessageThreadMessagesPropsMappedFromDispatch &
  WithStyles<typeof styles>;

const MessageThreadMessages: React.FC<MessageThreadProps> = ({
  getMessageThread,
  subscribeMessageThreadPostedMessage,
  handleChangeMessageThreadInputMessage,
  handleSubmitMessage,
  handleDropFiles,
  attachFile,
  projectName,
  messageThread,
  messageThreadUrlCode,
  messageThreadInputText,
  isSampleProject,
  classes
}) => {
  const location = useLocation();
  const [messageThreadUploadedFiles, setMessageThreadUploadedFiles] =
    useState<MessageThreadUploadedFiles>([]);

  const sortUploadFilesOrderByPostedAtDesc = (
    uploadFileA: MessageThreadUploadedFile,
    uploadFileB: MessageThreadUploadedFile
  ): number => uploadFileB.postedAt.diff(uploadFileA.postedAt);

  useEffect(() => {
    // TODO: performance tuning.
    // cf. https://github.com/giftee/ikedayama/pull/1369/files#r312407618
    // collects nodes which includes files value.
    const nodesIncludeFiles = messageThread.messages.nodes.filter(
      node => !!node.files
    );
    // collect uploaded file list from above nodes variable.
    const nextMessageThreadUploadedFiles = nodesIncludeFiles
      .map(nodeIncludeFiles =>
        nodeIncludeFiles.files
          ? nodeIncludeFiles.files.map(file => ({
              uploadedFile: file,
              contributorName: nodeIncludeFiles.contributor.name,
              postedAt: moment(nodeIncludeFiles.postedAt)
            }))
          : []
      )
      .flat();
    setMessageThreadUploadedFiles(nextMessageThreadUploadedFiles);
  }, [messageThread.messages.nodes]);

  useEffect(() => {
    getMessageThread(messageThreadUrlCode, location.hash.replace('#', ''));
  }, [messageThreadUrlCode]);

  useEffect(() => {
    subscribeMessageThreadPostedMessage();
  }, [messageThreadUrlCode]);

  return (
    <Fragment>
      <Grid
        item
        xs={6}
        className={clsx({
          [classes.messageViewportGridSample]: isSampleProject,
          [classes.messageViewportGrid]: !isSampleProject
        })}
        data-cy="messageThreadMessages"
        onDragOver={event => event.preventDefault()}
        onDrop={handleDropFiles}
      >
        <div className={classes.messageViewport}>
          <Typography
            variant="subtitle2"
            className={clsx(classes.subContentTitle, classes.projectName)}
          >
            {projectName}
          </Typography>
          <div id="messageThreadContainer" className={classes.messages}>
            {messageThread.messages.nodes.map((node, i) => (
              <div id={node.requestCode} key={i} className={classes.message}>
                <Grid container justifyContent="flex-start">
                  <Grid item>
                    <Typography
                      variant="body2"
                      className={classes.contributorName}
                    >
                      {node.contributor.name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="caption"
                      className={classes.companyName}
                    >
                      {'distributionPartner' in node.contributor
                        ? node.contributor.distributionPartner.name
                        : '株式会社ギフティ'}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      className={classes.postedAt}
                    >
                      {moment(node.postedAt).format(dateFormatForDisplay)}
                    </Typography>
                  </Grid>
                </Grid>
                {node.files
                  ? node.files.map((file, i) => (
                      <div key={i} className={classes.uploadFileWrapper}>
                        <UploadFileContainer uploadedFile={file} />
                      </div>
                    ))
                  : null}
                {node.text ? (
                  <Typography
                    variant="body2"
                    className={classes.messageContent}
                  >
                    <Linkify
                      tagName="span"
                      options={{ target: '_blank', rel: 'noopener noreferrer' }}
                    >
                      {node.text.content}
                    </Linkify>
                  </Typography>
                ) : null}
              </div>
            ))}
          </div>
          {isSampleProject ? (
            <div className={classes.noteForSampleProject}>
              <p>お問い合わせ事項がある場合、</p>
              <p>
                弊社営業担当にご連絡いただくか、
                <Link to="/console/">こちらのリンク先</Link>の
              </p>
              <p>画面右下にある「サポート」よりご連絡ください。</p>
            </div>
          ) : (
            <div className={classes.messageForm}>
              <Box
                border={1}
                borderColor="grey.500"
                borderRadius={1}
                className={classes.inputBox}
              >
                <input
                  style={{ display: 'none' }}
                  id="upload-file-button"
                  type="file"
                  onChange={attachFile}
                  data-cy="messageInputFile"
                />
                <label htmlFor="upload-file-button">
                  <IconButton
                    className={classes.iconButton}
                    aria-label="Menu"
                    component="span"
                    size="large"
                  >
                    <AttachFileIcon className={classes.icon} />
                  </IconButton>
                </label>
                <InputBase
                  className={classes.inputBase}
                  placeholder="メッセージを入力してください。"
                  multiline
                  autoFocus
                  minRows={1}
                  maxRows={20}
                  value={messageThreadInputText}
                  onChange={handleChangeMessageThreadInputMessage}
                  data-cy="messageInputTextField"
                />
              </Box>
              <div className={classes.submitButtonArea}>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.submitButton}
                  disabled={
                    messageThreadInputText
                      ? messageThreadInputText.length === 0
                      : true
                  }
                  onClick={event =>
                    handleSubmitMessage(
                      messageThreadInputText,
                      messageThreadUrlCode
                    )
                  }
                >
                  送信
                </Button>
              </div>
            </div>
          )}
        </div>
      </Grid>
      <Grid item xs className={classes.uploadedFilesViewportGrid}>
        <Typography variant="subtitle2" className={classes.subContentTitle}>
          アップロードされたファイル
        </Typography>
        <div id="uploadedFileContainer" className={classes.uploadedFiles}>
          {messageThreadUploadedFiles
            ? messageThreadUploadedFiles
                .sort(sortUploadFilesOrderByPostedAtDesc)
                .map((messageThreadUploadedFile, i) => (
                  <UploadFileContainer
                    key={i}
                    uploadedFile={messageThreadUploadedFile.uploadedFile}
                  >
                    <Grid container className={classes.uploadDetailGrid}>
                      <Grid item>
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          className={classes.uploadedBy}
                          data-cy={`messageThreadUploadFile-contributorName-${i}`}
                        >
                          {messageThreadUploadedFile.contributorName}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="caption" color="textSecondary">
                          {messageThreadUploadedFile.postedAt.format(
                            dateFormatForDisplay
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </UploadFileContainer>
                ))
            : null}
        </div>
      </Grid>
      {/* Dialogは強制的にbody要素の末子として配置される */}
      <MessageThreadDialogContainer />
    </Fragment>
  );
};

export default withStyles(styles)(MessageThreadMessages);
