import { ERROR_MESSAGE_FOR_PAGE_VALIDATION } from '@common/middleware/errorMessages';
import { validateProjectDraftFailure } from '@console/actions/actions';
import { validateGiftConfigDeliveryConfigThunk } from '@console/actions/thunks/validateProjectDraft/validateGiftConfig/validateDeliveryConfig';
import { getActiveStep } from '@console/common/getActiveStep';
import GiftCardDeliveryConfig from '@console/components/common/deliveryConfig/GiftCardDeliveryConfig';
import {
  isBulkSolutionSystemSelected,
  isApiSolutionSystemSelected,
  isGcpSolutionSystemSelected
} from '@console/selectors/getSelectedProjectDraftForDisplay';
import { connect } from 'react-redux';
import { goNextStep } from '../StepHandlerContainer';
import type {
  AppThunkAction,
  AppThunkDispatch
} from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';
import type { NavigateFunction } from 'react-router';

const handleStepNextFromParent =
  (navigate: NavigateFunction): AppThunkAction<Promise<void>> =>
  dispatch =>
    dispatch(validateGiftConfigDeliveryConfigThunk())
      .then(() => {
        const { parentStep, childStep } = getActiveStep();
        goNextStep(navigate, parentStep, childStep);
      })
      .catch(() => {
        dispatch(
          validateProjectDraftFailure(ERROR_MESSAGE_FOR_PAGE_VALIDATION)
        );
      });

const mapStateToProps = (state: AppState) => ({
  giftConfig:
    state.selectedProjectDraft.projectJson.giftConfigs[
      state.selectedProjectDraft.projectJson.selectedGiftConfigIndex
    ],
  isBulkSolutionSystemSelected: isBulkSolutionSystemSelected(state),
  isApiSolutionSystemSelected: isApiSolutionSystemSelected(state),
  isGcpSolutionSystemSelected: isGcpSolutionSystemSelected(state)
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  handleStepNextFromParent: (navigate: NavigateFunction) =>
    dispatch(handleStepNextFromParent(navigate))
});

export type GiftCardDeliveryConfigPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type GiftCardDeliveryConfigPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GiftCardDeliveryConfig);
