import {
  Typography,
  Grid,
  Table,
  TableBody,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { useNavigate } from 'react-router';
import AccountContainer from '../../containers/account/AccountContainer';
import CommonErrorContainer from '../../containers/common/CommonErrorContainer';
import DetailTableRow from '../common/DetailTableRow';
import type {
  DistributionPartnerEditPropsMappedFromDispatch,
  DistributionPartnerEditPropsMappedFromState
} from '../../containers/account/DistributionPartnerEditContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    sections: {
      maxWidth: 600
    },
    section: {
      marginBottom: theme.spacing(6)
    },
    sectionTitle: {
      marginBottom: theme.spacing(3)
    },
    input: {
      marginBottom: theme.spacing(3)
    },
    prefecture: {
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(2)
    },
    limitedInput: {
      width: 120
    },
    searchButton: {
      margin: theme.spacing(2)
    },
    button: {
      marginTop: theme.spacing(5),
      width: theme.spacing(15)
    },
    errorMessage: {
      minHeight: theme.spacing(3),
      fontSize: 14
    }
  });

type DistributionPartnerEditProps =
  DistributionPartnerEditPropsMappedFromDispatch &
    DistributionPartnerEditPropsMappedFromState &
    WithStyles<typeof styles>;

const DistributionPartnerEdit: React.FC<DistributionPartnerEditProps> = ({
  classes,
  distributionPartner,
  prefectures,
  errors,
  onChangeBillingAddressName,
  onChangeBillingAddressDivision,
  onChangeBillingAddressPostalCode,
  onChangeBillingAddressPrefectureId,
  onChangeBillingAddressCity,
  onChangeBillingAddressAddress,
  onChangeBillingAddressOptionalAddress,
  onClickSearchAddress,
  onClickUpdateBillingAddress
}) => {
  const navigate = useNavigate();
  const billingAddress = distributionPartner.billingAddresses[0];

  return (
    <AccountContainer>
      <div className={classes.sections}>
        <CommonErrorContainer />
        <section className={classes.section}>
          <Typography
            variant="h6"
            color="textPrimary"
            className={classes.sectionTitle}
          >
            企業情報
          </Typography>
          <Table>
            <TableBody>
              <DetailTableRow label="企業名" value={distributionPartner.name} />
            </TableBody>
          </Table>
        </section>
        <section className={classes.section}>
          <Typography
            variant="h6"
            color="textPrimary"
            className={classes.sectionTitle}
          >
            請求先情報
          </Typography>

          <Grid item xs={12} className={classes.input}>
            <TextField
              variant="standard"
              name="billingAddressName"
              label="担当者名"
              value={billingAddress.name}
              fullWidth
              size="small"
              onChange={onChangeBillingAddressName}
              data-cy="billingAddressName"
              required
              error={!!errors.accountDistributionPartnerEdit.billingAddressName}
              inputProps={{ maxLength: 255 }}
            />
            <Typography
              id="errorMessageForBillingAddressName"
              className={classes.errorMessage}
              color="error"
            >
              {errors.accountDistributionPartnerEdit.billingAddressName}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.input}>
            <TextField
              variant="standard"
              name="billingAddressDivision"
              label="部署名"
              value={billingAddress.division}
              fullWidth
              size="small"
              onChange={onChangeBillingAddressDivision}
              data-cy="billingAddressDivision"
              required
              error={
                !!errors.accountDistributionPartnerEdit.billingAddressDivision
              }
              inputProps={{ maxLength: 255 }}
            />
            <Typography
              id="errorMessageForBillingAddressDivision"
              className={classes.errorMessage}
              color="error"
            >
              {errors.accountDistributionPartnerEdit.billingAddressDivision}
            </Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent="flex-start"
            alignItems="center"
            xs={12}
          >
            <TextField
              variant="standard"
              required
              name="billingAddressPostalCode"
              label="郵便番号"
              value={billingAddress.postalCode}
              helperText="※ハイフン(-)なし"
              fullWidth
              size="small"
              className={classes.limitedInput}
              onChange={onChangeBillingAddressPostalCode}
              data-cy="billingAddressPostalCode"
              error={
                !!errors.accountDistributionPartnerEdit.billingAddressPostalCode
              }
              inputProps={{ maxLength: 7 }}
            />
            <Button
              color="primary"
              variant="outlined"
              data-cy="searchPostcode"
              className={classes.searchButton}
              onClick={onClickSearchAddress}
            >
              住所検索
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography
              id="errorMessageForBillingAddressPostalCode"
              className={classes.errorMessage}
              color="error"
            >
              {errors.accountDistributionPartnerEdit.billingAddressPostalCode}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.prefecture}>
            <FormControl
              variant="standard"
              required
              className={classes.limitedInput}
              error={
                !!errors.accountDistributionPartnerEdit.billingAddressPrefecture
              }
            >
              <InputLabel id="billingAddressPrefectureSelectLabel">
                都道府県
              </InputLabel>
              <Select
                variant="standard"
                labelId="billingAddressPrefectureSelectLabel"
                value={billingAddress.prefecture.id}
                name="billingAddressPrefectureSelect"
                data-cy="billingAddressPrefectureSelect"
                onChange={e =>
                  onChangeBillingAddressPrefectureId(Number(e.target.value))
                }
              >
                {prefectures.map((prefecture, i) => (
                  <MenuItem key={i} value={prefecture.id}>
                    {prefecture.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography
              id="errorMessageForBillingAddressPrefecture"
              className={classes.errorMessage}
              color="error"
            >
              {errors.accountDistributionPartnerEdit.billingAddressPrefecture}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.input}>
            <TextField
              variant="standard"
              required
              name="billingAddressCity"
              label="市区町村"
              value={billingAddress.city}
              fullWidth
              size="small"
              onChange={onChangeBillingAddressCity}
              data-cy="billingAddressCity"
              error={!!errors.accountDistributionPartnerEdit.billingAddressCity}
              inputProps={{ maxLength: 255 }}
            />
            <Typography
              id="errorMessageForBillingAddressCity"
              className={classes.errorMessage}
              color="error"
            >
              {errors.accountDistributionPartnerEdit.billingAddressCity}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.input}>
            <TextField
              variant="standard"
              name="billingAddressAddress"
              label="番地"
              required
              value={billingAddress.address}
              fullWidth
              size="small"
              onChange={onChangeBillingAddressAddress}
              data-cy="billingAddressAddress"
              error={
                !!errors.accountDistributionPartnerEdit.billingAddressAddress
              }
              inputProps={{ maxLength: 255 }}
            />
            <Typography
              id="errorMessageForBillingAddressAddress"
              className={classes.errorMessage}
              color="error"
            >
              {errors.accountDistributionPartnerEdit.billingAddressAddress}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.input}>
            <TextField
              variant="standard"
              name="billingAddressOptionalAddress"
              label="建物名"
              value={billingAddress.optionalAddress}
              fullWidth
              size="small"
              onChange={onChangeBillingAddressOptionalAddress}
              data-cy="billingAddressOptionalAddress"
              inputProps={{ maxLength: 255 }}
            />
          </Grid>
          <Grid item container justifyContent="center" xs={12}>
            <Button
              onClick={() => onClickUpdateBillingAddress(navigate)}
              color="primary"
              variant="contained"
              className={classes.button}
              data-cy="billingAddressUpdateButton"
            >
              <Typography variant="body1">変更</Typography>
            </Button>
          </Grid>
        </section>
      </div>
    </AccountContainer>
  );
};

export default withStyles(styles)(DistributionPartnerEdit);
