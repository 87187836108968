import { Typography, Grid, TextField, Button } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { useNavigate } from 'react-router';
import AccountContainer from '../../containers/account/AccountContainer';
import CommonErrorContainer from '../../containers/common/CommonErrorContainer';
import type {
  DistributionPartnerUserEditPropsMappedFromDispatch,
  DistributionPartnerUserEditPropsMappedFromState
} from '../../containers/account/DistributionPartnerUserEditContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    sections: {
      maxWidth: 600
    },
    section: {
      marginBottom: theme.spacing(6)
    },
    sectionTitle: {
      marginBottom: theme.spacing(3)
    },
    input: {
      marginBottom: theme.spacing(3)
    },
    button: {
      marginTop: theme.spacing(5),
      width: theme.spacing(15)
    },
    errorMessage: {
      minHeight: theme.spacing(3),
      fontSize: 14
    }
  });

type DistributionPartnerUserEditProps =
  DistributionPartnerUserEditPropsMappedFromDispatch &
    DistributionPartnerUserEditPropsMappedFromState &
    WithStyles<typeof styles>;

const DistributionPartnerUserEdit: React.FC<
  DistributionPartnerUserEditProps
> = ({
  classes,
  distributionPartnerUser,
  onChangeDistributionPartnerUserName,
  onChangeDistributionPartnerUserDivision,
  onChangeDistributionPartnerUserPhoneNumber,
  onClickUpdateDistributionPartnerUser,
  errors
}) => {
  const navigate = useNavigate();

  return (
    <AccountContainer>
      <div className={classes.sections}>
        <CommonErrorContainer />
        <section className={classes.section}>
          <Typography
            variant="h6"
            color="textPrimary"
            className={classes.sectionTitle}
          >
            担当者情報
          </Typography>
          <Grid item xs={12} className={classes.input}>
            <TextField
              variant="standard"
              name="distributionPartnerUserName"
              label="担当者名"
              value={distributionPartnerUser.name}
              size="small"
              data-cy="distributionPartnerUserName"
              required
              error={
                !!errors.accountDistributionPartnerUserEdit
                  .distributionPartnerUserName
              }
              inputProps={{ maxLength: 255 }}
              onChange={onChangeDistributionPartnerUserName}
            />
            <Typography
              id="errorMessageForDistributionPartnerUserName"
              className={classes.errorMessage}
              color="error"
            >
              {
                errors.accountDistributionPartnerUserEdit
                  .distributionPartnerUserName
              }
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.input}>
            <TextField
              variant="standard"
              name="distributionPartnerUserDivision"
              label="部署名"
              value={distributionPartnerUser.division}
              fullWidth
              size="small"
              data-cy="distributionPartnerUserDivision"
              required
              error={
                !!errors.accountDistributionPartnerUserEdit
                  .distributionPartnerUserDivision
              }
              inputProps={{ maxLength: 255 }}
              onChange={onChangeDistributionPartnerUserDivision}
            />
            <Typography
              id="errorMessageForDistributionPartnerUserDivision"
              className={classes.errorMessages}
              color="error"
            >
              {
                errors.accountDistributionPartnerUserEdit
                  .distributionPartnerUserDivision
              }
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.input}>
            <TextField
              variant="standard"
              name="distributionPartnerUserPhoneNumber"
              label="電話番号"
              value={distributionPartnerUser.phoneNumber}
              helperText="※ハイフン(-)なし"
              fullWidth
              size="small"
              data-cy="distributionPartnerUserPhoneNumber"
              required
              error={
                !!errors.accountDistributionPartnerUserEdit
                  .distributionPartnerUserPhoneNumber
              }
              inputProps={{ maxLength: 255 }}
              onChange={onChangeDistributionPartnerUserPhoneNumber}
            />
            <Typography
              id="errorMessageForDistributionPartnerUserPhoneNumber"
              className={classes.errorMessages}
              color="error"
            >
              {
                errors.accountDistributionPartnerUserEdit
                  .distributionPartnerUserPhoneNumber
              }
            </Typography>
          </Grid>
          <Grid item container justifyContent="center" xs={12}>
            <Button
              onClick={() => onClickUpdateDistributionPartnerUser(navigate)}
              color="primary"
              variant="contained"
              className={classes.button}
              data-cy="distributionPartnerUserUpdateButton"
            >
              <Typography variant="body1">変更</Typography>
            </Button>
          </Grid>
        </section>
      </div>
    </AccountContainer>
  );
};

export default withStyles(styles)(DistributionPartnerUserEdit);
