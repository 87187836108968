import CloseIcon from '@mui/icons-material/Close';
import MessageIcon from '@mui/icons-material/Message';
import { Fab } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useParams, useNavigate, useLocation } from 'react-router';
import { isMessageThreadShown } from '../../containers/project/FloatingButtonContainer';
import type {
  FloatingButtonPropsMappedFromState,
  FloatingButtonPropsMappedFromDispatch
} from '../../containers/project/FloatingButtonContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(8),
    right: theme.spacing(8),
    backgroundColor: theme.palette.primary.main
  },
  icon: {
    fill: 'white',
    '&:hover': theme.palette.primary.main
  }
});

export type PathParams = {
  urlCode?: string;
};

type FloatingButtonProps = FloatingButtonPropsMappedFromState &
  FloatingButtonPropsMappedFromDispatch &
  WithStyles<typeof styles>;

const FloatingButton: React.FC<FloatingButtonProps> = ({
  toggleMessageThread,
  classes
}) => {
  const params = useParams<PathParams>();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Fab
      color="primary"
      aria-label="messageThread"
      className={classes.fab}
      onClick={() => toggleMessageThread(navigate, params, location)}
      data-cy="floatingButtonToMessageThread"
    >
      {isMessageThreadShown(location.pathname) ? (
        <CloseIcon className={classes.icon} />
      ) : (
        <MessageIcon className={classes.icon} />
      )}
    </Fab>
  );
};

export default withStyles(styles)(FloatingButton);
