import { getActiveStep } from '@console/common/getActiveStep';
import { isGifteeBoxGiftConfig } from '@console/common/projectDraft/giftConfig';
import CampaignOverview from '@console/containers/projectDraft/CampaignOverviewContainer';
import ConfirmationContainer from '@console/containers/projectDraft/ConfirmationContainer';
import ContentsSelectionContainer from '@console/containers/projectDraft/ContentsSelectionContainer';
import DeliveryConfigContainer from '@console/containers/projectDraft/DeliveryConfigContainer';
import GiftConfigsContainer from '@console/containers/projectDraft/GiftConfigsContainer';
import GiftDistributionContainer from '@console/containers/projectDraft/GiftDistributionContainer';
import GiftTicketTypeConfigContainer from '@console/containers/projectDraft/GiftTicketTypeConfigContainer';
import MessageCardConfigContainer from '@console/containers/projectDraft/MessageCardConfigContainer';
import MessageCardUsingContainer from '@console/containers/projectDraft/MessageCardUsingContainer';
import { Route, Navigate, useLocation } from 'react-router';
import type {
  RootPropsMappedFromState,
  RootPropsMappedFromDispatch
} from '@console/containers/projectDraft/RootContainer';

type RootProps = RootPropsMappedFromState & RootPropsMappedFromDispatch;

export const enum ParentSteps {
  CAMPAIGN_OVERVIEW = 0,
  GIFT_DISTRIBUTION = 1,
  GIFT_CONFIG = 2,
  CONFIRMATION = 3
}

export const enum CampaignOverviewChildSteps {
  CAMPAIGN_OVERVIEW = 0
}

export const enum GiftDistributionChildSteps {
  GIFT_DISTRIBUTION = 0
}

export const enum GiftConfigChildSteps {
  GIFT_CONFIGS = 0,
  GIFT_TICKET_TYPE_CONFIG = 1,
  CONTENTS_SELECTION = 2,
  DELIVERY_CONFIG = 3,
  MESSAGE_CARD_USING = 4,
  MESSAGE_CARD_CONFIG = 5
}

export const enum ConfirmationChildSteps {
  CONFIRMATION = 0
}

export const isCampaignOverview = (
  parentStep: number,
  childStep: number
): boolean =>
  parentStep === ParentSteps.CAMPAIGN_OVERVIEW &&
  childStep === CampaignOverviewChildSteps.CAMPAIGN_OVERVIEW;

export const isGiftDistribution = (
  parentStep: number,
  childStep: number
): boolean =>
  parentStep === ParentSteps.GIFT_DISTRIBUTION &&
  childStep === GiftDistributionChildSteps.GIFT_DISTRIBUTION;

export const isGiftConfigs = (parentStep: number, childStep: number): boolean =>
  parentStep === ParentSteps.GIFT_CONFIG &&
  childStep === GiftConfigChildSteps.GIFT_CONFIGS;

export const isTicketTypeConfig = (
  parentStep: number,
  childStep: number
): boolean =>
  parentStep === ParentSteps.GIFT_CONFIG &&
  childStep === GiftConfigChildSteps.GIFT_TICKET_TYPE_CONFIG;

export const isContentsSelection = (
  parentStep: number,
  childStep: number
): boolean =>
  parentStep === ParentSteps.GIFT_CONFIG &&
  childStep === GiftConfigChildSteps.CONTENTS_SELECTION;

export const isDeliveryConfig = (
  parentStep: number,
  childStep: number
): boolean =>
  parentStep === ParentSteps.GIFT_CONFIG &&
  childStep === GiftConfigChildSteps.DELIVERY_CONFIG;

export const isMessageCardUsing = (
  parentStep: number,
  childStep: number
): boolean =>
  parentStep === ParentSteps.GIFT_CONFIG &&
  childStep === GiftConfigChildSteps.MESSAGE_CARD_USING;

export const isMessageCardConfig = (
  parentStep: number,
  childStep: number
): boolean =>
  parentStep === ParentSteps.GIFT_CONFIG &&
  childStep === GiftConfigChildSteps.MESSAGE_CARD_CONFIG;

export const isConfirmation = (
  parentStep: number,
  childStep: number
): boolean =>
  parentStep === ParentSteps.CONFIRMATION &&
  childStep === ConfirmationChildSteps.CONFIRMATION;

const Root: React.FC<RootProps> = ({ giftConfigs, selectedGiftConfig }) => {
  const location = useLocation();
  const { parentStep, childStep } = getActiveStep();

  const redirectToStep = (parentStep: ParentSteps, childStep: number) => (
    <Route>
      <Navigate
        to={`${location.pathname}?parentStep=${parentStep}&childStep=${childStep}`}
        replace
      />
    </Route>
  );

  if (isCampaignOverview(parentStep, childStep)) {
    return <CampaignOverview />;
  }
  if (isGiftDistribution(parentStep, childStep)) {
    return <GiftDistributionContainer />;
  }
  if (isGiftConfigs(parentStep, childStep)) {
    return <GiftConfigsContainer />;
  }
  if (giftConfigs.length === 0) {
    return redirectToStep(
      ParentSteps.GIFT_CONFIG,
      GiftConfigChildSteps.GIFT_CONFIGS
    );
  }
  if (isTicketTypeConfig(parentStep, childStep)) {
    return <GiftTicketTypeConfigContainer />;
  }
  if (isContentsSelection(parentStep, childStep)) {
    return <ContentsSelectionContainer />;
  }
  if (isDeliveryConfig(parentStep, childStep)) {
    return <DeliveryConfigContainer />;
  }
  if (isMessageCardUsing(parentStep, childStep)) {
    if (isGifteeBoxGiftConfig(selectedGiftConfig)) {
      return redirectToStep(
        ParentSteps.GIFT_CONFIG,
        GiftConfigChildSteps.GIFT_CONFIGS
      );
    }
    return <MessageCardUsingContainer />;
  }
  if (isMessageCardConfig(parentStep, childStep)) {
    if (isGifteeBoxGiftConfig(selectedGiftConfig)) {
      return redirectToStep(
        ParentSteps.GIFT_CONFIG,
        GiftConfigChildSteps.GIFT_CONFIGS
      );
    }
    return <MessageCardConfigContainer />;
  }
  if (isConfirmation(parentStep, childStep)) {
    return <ConfirmationContainer />;
  }
  return (
    <Route>
      <Navigate
        to={`${location.pathname}?parentStep=${ParentSteps.CAMPAIGN_OVERVIEW}&childStep=${CampaignOverviewChildSteps.CAMPAIGN_OVERVIEW}`}
        replace
      />
    </Route>
  );
};

export default Root;
