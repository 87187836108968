export enum FeatureTypes {
  HOGE = 'feature-hoge',
  ACCOUNT = 'feature-account',
  NEW_APPLY_START = 'new-apply-start'
}

export const isFeatureEnable = (feature: FeatureTypes): boolean => {
  const searchParams = new URLSearchParams(location.search);
  return searchParams.get(feature) === 'true';
};
