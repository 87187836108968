import { getRoundPercentage } from '@common/lib/getPercentage';
import {
  GIFTEE_BOX_DISPLAY_NAME,
  SELECTION__REPORTS_CSV_HEADER,
  SELECTION_REPORT_BUTTON_LABEL
} from '@console/common/constants';
import { ProjectContents } from '@console/components/MainContents';
import CSVDownloadButton from '@console/components/common/CSVDownloadButton';
import ContentRow from '@console/components/common/ContentRow';
import DisplaySelectedGifteeBoxConfigForAPIProjectContainer from '@console/containers/project/gifteeBoxConfigs/exchangeReport/DisplaySelectedGifteeBoxConfigForAPIProjectContainer';
import DisplaySelectedGifteeBoxConfigForBulkProjectContainer from '@console/containers/project/gifteeBoxConfigs/exchangeReport/DisplaySelectedGifteeBoxConfigForBulkProjectContainer';
import DisplaySelectedGifteeBoxConfigForGCPProjectContainer from '@console/containers/project/gifteeBoxConfigs/exchangeReport/DisplaySelectedGifteeBoxConfigForGCPProjectContainer';
import { MAX_DISPLAY_COUNT } from '@console/selectors/aggregatedGifteeBoxSelectedContentResults';
import { faFileCsv } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { KeyboardArrowLeft } from '@mui/icons-material';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { useCallback, useEffect } from 'react';
import { Link, useParams } from 'react-router';
import type {
  ContentPercentageTablePropsMappedFromDispatch,
  ContentPercentageTablePropsMappedFromState
} from '@console/containers/project/gifteeBoxConfigs/ExchangeReportContainer';
import type { Theme } from '@mui/material';

import type { StyleRules, WithStyles } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  tableContainer: {
    overflowX: 'scroll',
    whiteSpace: 'nowrap'
  },
  contentTableCell: {
    width: '60%',
    minWidth: 400
  },
  tableCell: {
    width: '20%',
    minWidth: 100
  },
  noDataTableCell: {
    height: 80,
    lineHeight: 5.25,
    padding: theme.spacing(0, 0, 0, 2)
  },
  link: {
    textDecoration: 'none',
    display: 'inline-block',
    padding: theme.spacing(2, 0, 0, 0)
  },
  icon: {
    fill: theme.palette.primary.main,
    verticalAlign: 'middle'
  },
  linkLabel: {
    verticalAlign: 'middle'
  },
  csvDownloadButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: theme.spacing(2, 2)
  },
  empty: {
    paddingTop: theme.spacing(4),
    textAlign: 'center'
  },
  emptyGifteeBoxExchangeReport: {
    margin: theme.spacing(5),
    color: theme.palette.icon.file
  }
});

type PathParams = {
  gifteeBoxConfigUrlCode?: UrlCode;
};

type ShowProps = ContentPercentageTablePropsMappedFromState &
  ContentPercentageTablePropsMappedFromDispatch &
  WithStyles<typeof styles>;

const ExchangeReport: React.FC<ShowProps> = ({
  aggregatedGifteeBoxSelectedContentResults,
  selectedGifteeBoxConfig,
  isApiProject,
  isBulkProject,
  isGcpProject,
  classes,
  remainingContentsSelectedPercentage,
  onMount,
  aggregatedGifteeBoxSelectedContentResultsForCsvExport,
  getAggregateGifteeBoxSelectedContentPercentage,
  deleteAggregateGifteeBoxSelectedContentPercentage
}) => {
  const params = useParams<PathParams>();
  const { gifteeBoxConfigUrlCode } = params;

  useEffect(() => {
    if (gifteeBoxConfigUrlCode) {
      onMount(gifteeBoxConfigUrlCode);
    }
  }, []);

  useEffect(() => {
    if (gifteeBoxConfigUrlCode) {
      getAggregateGifteeBoxSelectedContentPercentage(gifteeBoxConfigUrlCode);

      return () => {
        deleteAggregateGifteeBoxSelectedContentPercentage();
      };
    }
  }, [gifteeBoxConfigUrlCode]);

  const getCsvFileName = useCallback(
    () =>
      `giftee_Box_reports_${gifteeBoxConfigUrlCode}_${new Date()
        .toLocaleString('ja-JP')
        .replace(' ', '_')}`,
    [gifteeBoxConfigUrlCode]
  );

  return (
    <>
      {!selectedGifteeBoxConfig ? (
        <div className={classes.empty}>
          <FontAwesomeIcon
            icon={faFileCsv}
            size="3x"
            className={classes.emptyGifteeBoxExchangeReport}
            data-cy="dataEmptyIcon"
          />
          <Typography
            variant="subtitle1"
            color="textSecondary"
            data-cy="dataEmptyString"
          >{`${GIFTEE_BOX_DISPLAY_NAME}選択実績はありません`}</Typography>
        </div>
      ) : (
        <>
          {
            <Link
              to={`/console/projects/${selectedGifteeBoxConfig.urlCode}/${ProjectContents.SELECTION_REPORT}`}
              className={classes.link}
            >
              <KeyboardArrowLeft className={classes.icon} />
              <Typography
                variant="body2"
                color="primary"
                display="inline"
                className={classes.linkLabel}
              >
                {`${GIFTEE_BOX_DISPLAY_NAME}選択実績に戻る`}
              </Typography>
            </Link>
          }

          {isBulkProject && (
            <DisplaySelectedGifteeBoxConfigForBulkProjectContainer />
          )}

          {isApiProject && (
            <DisplaySelectedGifteeBoxConfigForAPIProjectContainer />
          )}

          {isGcpProject && (
            <DisplaySelectedGifteeBoxConfigForGCPProjectContainer />
          )}
          <div className={classes.csvDownloadButtonContainer}>
            <CSVDownloadButton
              header={SELECTION__REPORTS_CSV_HEADER}
              data={aggregatedGifteeBoxSelectedContentResultsForCsvExport}
              fileName={getCsvFileName()}
            >
              {SELECTION_REPORT_BUTTON_LABEL}
            </CSVDownloadButton>
          </div>
          <div className={classes.tableContainer}>
            <Table>
              <TableHead data-cy="tableHeader">
                <TableRow>
                  <TableCell>
                    <Typography>商品名</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>選択率</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {aggregatedGifteeBoxSelectedContentResults.length !== 0 ? (
                  <>
                    {aggregatedGifteeBoxSelectedContentResults.map(
                      (
                        aggregatedGifteeBoxSelectedContentResult,
                        aggregatedGifteeBoxSelectedContentResultIndex
                      ) => {
                        if (
                          aggregatedGifteeBoxSelectedContentResultIndex <
                          MAX_DISPLAY_COUNT
                        ) {
                          return (
                            <TableRow
                              key={
                                aggregatedGifteeBoxSelectedContentResultIndex
                              }
                              data-cy={`aggregatedGifteeBoxSelectedContentResult-tableRow-${aggregatedGifteeBoxSelectedContentResultIndex}`}
                            >
                              <TableCell
                                className={classes.contentTableCell}
                                data-cy={`aggregatedGifteeBoxSelectedContentResult-tableCell-content-${aggregatedGifteeBoxSelectedContentResultIndex}`}
                              >
                                <ContentRow
                                  contentName={
                                    aggregatedGifteeBoxSelectedContentResult
                                      .content.name
                                  }
                                  contentInternalName={
                                    aggregatedGifteeBoxSelectedContentResult
                                      .content.internalName
                                  }
                                  contentImageUrl={
                                    aggregatedGifteeBoxSelectedContentResult
                                      .content.imageUrl
                                  }
                                  contentAvailablePeriodText={
                                    aggregatedGifteeBoxSelectedContentResult
                                      .content.availablePeriod
                                  }
                                  contentBrandName={
                                    aggregatedGifteeBoxSelectedContentResult
                                      .content.brand.name
                                  }
                                />
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                data-cy={`aggregatedGifteeBoxSelectedContentResult-tableCell-percentage-${aggregatedGifteeBoxSelectedContentResultIndex}`}
                              >
                                <Typography>
                                  {`${getRoundPercentage(
                                    aggregatedGifteeBoxSelectedContentResult.selectedPercentage,
                                    1
                                  ).toString()}%`}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          );
                        } else if (
                          aggregatedGifteeBoxSelectedContentResultIndex ===
                          MAX_DISPLAY_COUNT
                        ) {
                          return (
                            <TableRow
                              key={
                                aggregatedGifteeBoxSelectedContentResultIndex
                              }
                            >
                              <TableCell
                                className={classes.contentTableCell}
                                data-cy="aggregatedGifteeBoxSelectedContentResult-tableCell-remainingContent"
                              >
                                <ContentRow
                                  contentName={'その他の商品'}
                                  contentInternalName={'その他の商品'}
                                  contentImageUrl={
                                    selectedGifteeBoxConfig.gifteeBoxTemplate
                                      .imageUrl
                                  }
                                  contentAvailablePeriodText={''}
                                  contentBrandName={''}
                                />
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                data-cy="aggregatedGifteeBoxSelectedContentResult-tableCell-remainingContentPercentage"
                              >
                                <Typography>
                                  {`${getRoundPercentage(
                                    remainingContentsSelectedPercentage,
                                    1
                                  ).toString()}%`}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          );
                        }
                      }
                    )}
                  </>
                ) : (
                  <TableRow>
                    <TableCell>
                      <Typography className={classes.noDataTableCell}>
                        データがありません
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>

          <p>
            {`※ ${GIFTEE_BOX_DISPLAY_NAME}で人気の商品を上位${MAX_DISPLAY_COUNT}
        件まで表示しています。`}
          </p>
        </>
      )}
    </>
  );
};

export default withStyles(styles)(ExchangeReport);
