import { getLocalePrice } from '@common/lib/getLocalePrice';
import { HELPSITE_CREDIT_LIMIT_URL } from '@console/common/constants';
import Note from '@console/components/common/Note';
import {
  Typography,
  Grid,
  Table,
  TableBody,
  Button,
  Link
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { useNavigate } from 'react-router';
import {
  isFeatureEnable,
  FeatureTypes
} from '../../../../common/lib/featureToggle';
import AccountContainer from '../../containers/account/AccountContainer';
import DetailTableRow from '../common/DetailTableRow';
import type {
  DistributionPartnerPropsMappedFromDispatch,
  DistributionPartnerPropsMappedFromState
} from '../../containers/account/DistributionPartnerContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    sections: {
      maxWidth: 600
    },
    section: {
      marginBottom: theme.spacing(6)
    },
    sectionTitle: {
      marginBottom: theme.spacing(3)
    },
    button: {
      height: 40,
      width: 100
    }
  });

type DistributionPartnerProps = DistributionPartnerPropsMappedFromDispatch &
  DistributionPartnerPropsMappedFromState &
  WithStyles<typeof styles>;

const DistributionPartner: React.FC<DistributionPartnerProps> = ({
  classes,
  distributionPartner,
  goDistributionPartnerEdit
}) => {
  const navigate = useNavigate();
  const billingAddress = distributionPartner.billingAddresses[0];
  return (
    <AccountContainer>
      <div className={classes.sections}>
        <section className={classes.section}>
          <Grid container justifyContent="space-between">
            <Typography
              variant="h6"
              color="textPrimary"
              className={classes.sectionTitle}
            >
              企業情報
            </Typography>
            {isFeatureEnable(FeatureTypes.ACCOUNT) ? (
              <Button
                onClick={() => goDistributionPartnerEdit(navigate)}
                color="primary"
                variant="contained"
                className={classes.button}
                data-cy="goEditDistributionPartner"
              >
                <Typography variant="body1">編集</Typography>
              </Button>
            ) : null}
          </Grid>
          <Table>
            <TableBody>
              <DetailTableRow label="企業名" value={distributionPartner.name} />
            </TableBody>
          </Table>
        </section>
        <section className={classes.section}>
          <Typography
            variant="body1"
            color="textPrimary"
            className={classes.sectionTitle}
          >
            請求先情報
          </Typography>
          <Table>
            <TableBody>
              <DetailTableRow label="担当者名" value={billingAddress.name} />
              <DetailTableRow label="部署" value={billingAddress.division} />
              <DetailTableRow
                label="電話番号"
                value={billingAddress.phoneNumber}
              />
              <DetailTableRow
                label="郵便番号"
                value={billingAddress.postalCode}
              />
              <DetailTableRow
                label="住所"
                value={
                  billingAddress.prefecture.name +
                  billingAddress.city +
                  billingAddress.address +
                  billingAddress.optionalAddress
                }
              />
            </TableBody>
          </Table>
        </section>
        <section className={classes.section}>
          <Typography
            variant="body1"
            color="textPrimary"
            className={classes.sectionTitle}
          >
            毎月のご利用限度額
          </Typography>
          <Note>
            ご利用限度額については{' '}
            <Link
              href={HELPSITE_CREDIT_LIMIT_URL}
              target="_blank"
              rel="noopener"
              underline="hover"
            >
              こちら
            </Link>{' '}
            をご参照ください。
          </Note>
          <Table>
            <TableBody>
              <DetailTableRow
                label=""
                value={
                  !!distributionPartner.creditLimitValue
                    ? getLocalePrice(distributionPartner.creditLimitValue)
                    : '未設定'
                }
              />
            </TableBody>
          </Table>
        </section>
      </div>
    </AccountContainer>
  );
};

export default withStyles(styles)(DistributionPartner);
