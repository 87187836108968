import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Fragment } from 'react';
import { useNavigate } from 'react-router';
import CommonErrorContainer from '../../containers/common/CommonErrorContainer';
import StepHandlerContainer from '../../containers/projectDraft/StepHandlerContainer';
import SectionTitle from './common/SectionTitle';
import type {
  GiftDistributionPropsMappedFromState,
  GiftDistributionPropsMappedFromDispatch
} from '../../containers/projectDraft/GiftDistributionContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {},
    title: {
      marginTop: theme.spacing(3)
    },
    subtitle: {
      marginTop: theme.spacing(10)
    },
    input: {
      padding: `1% 0`,
      paddingRight: `3%`
    },
    checkbox: {
      padding: 0,
      margin: '0 9px'
    },
    label: {
      paddingTop: 25
    },
    errorMessage: {
      minHeight: theme.spacing(3),
      fontSize: 14
    }
  });

type GiftDistributionProps = GiftDistributionPropsMappedFromState &
  GiftDistributionPropsMappedFromDispatch &
  WithStyles<typeof styles>;

const GiftDistribution: React.FC<GiftDistributionProps> = ({
  campaignGiftDistributionChannels,
  giftDistributionChannelCategories,
  campaignGiftDistributionChannelDescriptions,
  advertisingMedia,
  campaignAdvertisingMedia,
  errors,
  onChangeCampaignGiftDistributionChannel,
  onChangeGiftDistributionChannelDescription,
  onChangeCampaignAdvertisingMedium,
  onChangeCampaignAdvertisingMediumDescription,
  handleStepNextFromParent,
  classes
}) => {
  const navigate = useNavigate();

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="flex-start"
      className={classes.root}
    >
      <CommonErrorContainer errorKey={'projectDraftGiftDistribution'} />
      <Grid item xs={12} data-cy="giftDistributionChannelArea">
        <Grid item xs={12} className={classes.title}>
          <SectionTitle
            text="ギフトの配布方法を選択してください"
            helperText="※１つ以上選択してください"
          />
        </Grid>
        {giftDistributionChannelCategories.map(
          (giftDistributionChannelCategory, i) => (
            <Fragment key={i}>
              <Grid item xs={12} className={classes.input}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={campaignGiftDistributionChannels.includes(
                        giftDistributionChannelCategory.urlCode
                      )}
                      onChange={() =>
                        onChangeCampaignGiftDistributionChannel(
                          giftDistributionChannelCategory.urlCode
                        )
                      }
                      className={classes.checkbox}
                      data-cy={`giftDistributionChannelCategoryCheck${giftDistributionChannelCategory.urlCode}`}
                    />
                  }
                  label={giftDistributionChannelCategory.name}
                  className={classes.label}
                />
              </Grid>
              {giftDistributionChannelCategory.descriptionEnabled ? (
                <Grid item xs={12} lg={10} className={classes.input}>
                  <TextField
                    variant="standard"
                    disabled={
                      !campaignGiftDistributionChannels.includes(
                        giftDistributionChannelCategory.urlCode
                      )
                    }
                    value={
                      campaignGiftDistributionChannelDescriptions.find(
                        campaignGiftDistributionChannelDescription =>
                          campaignGiftDistributionChannelDescription.urlCode ===
                          giftDistributionChannelCategory.urlCode
                      )?.description || ''
                    }
                    onChange={e =>
                      onChangeGiftDistributionChannelDescription(
                        e,
                        giftDistributionChannelCategory.urlCode
                      )
                    }
                    fullWidth
                    inputProps={{ maxLength: 255 }}
                    placeholder={`${giftDistributionChannelCategory.name}詳細`}
                    helperText={`${giftDistributionChannelCategory.name}の詳細を入力して下さい`}
                    data-cy="descritpionRemarksOfGiftDistributionChannel"
                  />
                </Grid>
              ) : null}
            </Fragment>
          )
        )}
      </Grid>
      <Grid item xs={12} data-cy="advertisingMediumRegistrationArea">
        <Grid item xs={12} className={classes.subtitle}>
          <SectionTitle
            text="キャンペーン内容を露出する媒体を選択してください"
            helperText="※１つ以上選択してください"
          />
        </Grid>
        <Grid item xs={12} container alignItems="flex-end">
          {advertisingMedia.map((advertisingMedium, i) => (
            <Fragment key={i}>
              <Grid item xs={12} sm={12} md={3} className={classes.input}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={campaignAdvertisingMedia.some(
                        campaignAdvertisingMedium =>
                          campaignAdvertisingMedium.urlCode ===
                          advertisingMedium.urlCode
                      )}
                      onChange={() =>
                        onChangeCampaignAdvertisingMedium(
                          advertisingMedium.urlCode
                        )
                      }
                      className={classes.checkbox}
                      data-cy={`advertisingMediumRegistrationCheck${advertisingMedium.urlCode}`}
                    />
                  }
                  className={classes.label}
                  label={advertisingMedium.name}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={9}
                lg={7}
                className={classes.input}
              >
                <TextField
                  variant="standard"
                  disabled={
                    !campaignAdvertisingMedia.some(
                      campaignAdvertisingMedium =>
                        campaignAdvertisingMedium.urlCode ===
                        advertisingMedium.urlCode
                    )
                  }
                  value={
                    campaignAdvertisingMedia.find(
                      campaignAdvertisingMedium =>
                        campaignAdvertisingMedium.urlCode ===
                        advertisingMedium.urlCode
                    )?.description || ''
                  }
                  onChange={e =>
                    onChangeCampaignAdvertisingMediumDescription(
                      e,
                      advertisingMedium.urlCode
                    )
                  }
                  fullWidth
                  inputProps={{ maxLength: 255 }}
                  placeholder={advertisingMedium.descriptionPlaceholder}
                  helperText={advertisingMedium.descriptionExample}
                  data-cy={`descriptionOfAdvertisingMediumRegistration${advertisingMedium.urlCode}`}
                />
              </Grid>
            </Fragment>
          ))}
        </Grid>
      </Grid>
      <StepHandlerContainer
        handleStepNextFromParent={() => handleStepNextFromParent(navigate)}
      />
    </Grid>
  );
};

export default withStyles(styles)(GiftDistribution);
