import CommonErrorContainer from '@console/containers/common/CommonErrorContainer';
import StepHandlerContainer from '@console/containers/projectDraft/StepHandlerContainer';
import ConfirmedContentsContainer from '@console/containers/projectDraft/confirmation/ConfirmedContentsContainer';
import { Typography, Grid, Box } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import UnapprovedMessage from '../common/UnapprovedMessage';
import Notes from './confirmation/Notes';
import type {
  ConfirmationPropsMappedFromState,
  ConfirmationPropsMappedFromDispatch
} from '@console/containers/projectDraft/ConfirmationContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';
import type { ReactElement } from 'react';

const styles = (theme: Theme): StyleRules => ({
  title: {
    marginTop: theme.spacing(3)
  },
  errorMessage: {
    minHeight: theme.spacing(3),
    fontSize: 14
  },
  attention: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.divider,
    padding: theme.spacing()
  }
});

type ConfirmationProps = ConfirmationPropsMappedFromState &
  ConfirmationPropsMappedFromDispatch &
  WithStyles<typeof styles>;

const Confirmation: React.FC<ConfirmationProps> = ({
  onMount,
  handleStepNextFromParent,
  handleStepBackFromParent,
  isApiSolutionSystemSelected,
  isGcpSolutionSystemSelected,
  isDistributionPartnerInBusiness,
  giftConfigsHasValidationErrors,
  errors,
  classes
}) => {
  useEffect(() => onMount(), []);

  const navigate = useNavigate();

  const renderBody = (): ReactElement => <ConfirmedContentsContainer />;

  const renderNotes = (): ReactElement => <Notes />;

  return (
    <Grid container justifyContent="flex-start" alignItems="flex-start">
      <UnapprovedMessage inBusiness={isDistributionPartnerInBusiness} />
      <CommonErrorContainer />
      <CommonErrorContainer errorKey="projectDraftCampaignOverview" />
      <CommonErrorContainer errorKey="projectDraftGiftDistribution" />
      <Typography
        id="errorMessageForGiftConfigsCommon"
        className={classes.errorMessage}
        color="error"
      >
        {errors.projectDraftGiftConfigsCommon}
      </Typography>
      <Typography
        id="errorMessageForGiftConfigs"
        className={classes.errorMessage}
        color="error"
        data-cy="errorMessageForGiftConfigs"
      >
        {giftConfigsHasValidationErrors &&
          '・各ギフトをもう一度ご確認ください。'}
      </Typography>
      <Grid item xs={12} className={classes.title}>
        <Typography
          variant="h6"
          color="textPrimary"
          data-cy="confirmationTitle"
        >
          お申込内容の確認
        </Typography>
        {(isApiSolutionSystemSelected || isGcpSolutionSystemSelected) && (
          <Box className={classes.attention} mt={2}>
            「リアルタイム発行（API利用）」「giftee Plus
            Solutions」のご利用には弊社内の審査がございます。
            <br />
            審査結果次第では、ご希望の利用サービスをお受けできない場合がございますのであらかじめご了承ください。
          </Box>
        )}
      </Grid>
      {renderBody()}
      {renderNotes()}
      <StepHandlerContainer
        nextButtonName="利用規約に同意して申し込む"
        isNextButtonDisabled={!isDistributionPartnerInBusiness}
        handleStepNextFromParent={() => handleStepNextFromParent(navigate)}
        handleStepBackFromParent={() => handleStepBackFromParent(navigate)}
      />
    </Grid>
  );
};

export default withStyles(styles)(Confirmation);
