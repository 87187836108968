import { useEffect } from 'react';
import { useLocation } from 'react-router';

type ScrollRestorationOwnProps = {
  baseElementId: string;
};

type ScrollRestorationProps = ScrollRestorationOwnProps;

const ScrollRestoration: React.FC<ScrollRestorationProps> = ({
  baseElementId
}) => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    const base = document.getElementById(baseElementId);
    if (!!base) {
      base.scrollTop = 0;
    }
  }, [pathname, search]);

  return <></>;
};

export default ScrollRestoration;
