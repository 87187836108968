import getReadableDate from '@common/lib/getReadableDate';
import getTransitionTimeout from '@common/lib/getTransitionTimeout';
import getTruncatedString from '@common/lib/getTruncatedString';
import FileCopy from '@mui/icons-material/FileCopy';
import MessageIcon from '@mui/icons-material/Message';
import { Tooltip } from '@mui/material';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { useNavigate } from 'react-router';
import type { DashboardIndexPropsMappedFromDispatch } from '@console/containers/dashboard/IndexContainer';
import type { LocalProjectForDisplay } from '@console/selectors/getProjectsForDisplay';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => {
  const CONTENT_IMAGE_WIDTH = 40;
  const CONTENT_IMAGE_HEIGHT = 40;
  return createStyles({
    card: {
      width: theme.spacing(36),
      height: theme.spacing(25),
      borderRadius: theme.spacing(),
      boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
      textAlign: 'left'
    },
    contentImageGridItem: {
      marginRight: theme.spacing(1)
    },
    contentImage: {
      width: CONTENT_IMAGE_WIDTH,
      height: CONTENT_IMAGE_HEIGHT,
      objectFit: 'contain'
    },
    projectActions: {
      height: '20%',
      display: 'flex',
      justifyContent: 'flex-end'
    },
    projectGrid: {
      padding: `0 ${theme.spacing(2)} ${theme.spacing(4)}`
    },
    projectActionArea: {
      height: '80%'
    },
    projectActionAreaTitle: {
      position: 'absolute',
      top: 0,
      margin: `${theme.spacing(2)} ${theme.spacing(2)} 0`
    },
    projectActionAreaContentImages: {
      position: 'absolute',
      bottom: 0,
      marginLeft: theme.spacing(2)
    },
    projectName: {
      marginBottom: theme.spacing(1)
    },
    icon: {
      fill: theme.palette.primary.main
    }
  });
};

type DashboardOwnProps = {
  project: LocalProjectForDisplay;
  index: number;
  goProject: DashboardIndexPropsMappedFromDispatch['goProject'];
  goMessageThread: DashboardIndexPropsMappedFromDispatch['goMessageThread'];
  onClickCopyButtonHandler?: (event: React.MouseEvent, ...args: any) => void;
};

type DashboardProjectProps = DashboardOwnProps & WithStyles<typeof styles>;

const DashboardProject: React.FC<DashboardProjectProps> = ({
  classes,
  project,
  index,
  goProject,
  goMessageThread,
  onClickCopyButtonHandler
}) => {
  const navigate = useNavigate();

  return (
    <Grow
      in={Boolean(project)}
      {...(project ? { timeout: getTransitionTimeout(index) } : {})}
    >
      <Grid item className={classes.projectGrid}>
        <Card className={classes.card}>
          <CardActionArea
            className={classes.projectActionArea}
            onClick={() => goProject(navigate, project)}
            data-cy={`project-isSample-${project.isSample}-${project.urlCode}`}
          >
            <div className={classes.projectActionAreaTitle}>
              <Typography
                variant="body1"
                color="textPrimary"
                className={classes.projectName}
                data-cy={`projectName-isSample-${project.isSample}-${project.urlCode}`}
              >
                {getTruncatedString(project.name, 60, '...')}
              </Typography>
              <Typography
                variant="caption"
                color="textSecondary"
                data-cy={`projectAdvertisingPeriod-isSample-${project.isSample}-${project.urlCode}`}
              >
                {project.advertisingPeriod
                  ? `掲載期間：${getReadableDate(
                      project.advertisingPeriod.beginAt
                    )} 〜 ${getReadableDate(project.advertisingPeriod.endAt)}`
                  : ''}
              </Typography>
            </div>
            <div
              className={classes.projectActionAreaContentImages}
              data-cy={`projectContentImages-isSample-${project.isSample}-${project.urlCode}`}
            >
              <Grid container>
                {project.contentImageUrls.slice(0, 5).map(
                  (contentImageUrl, contentIndex) =>
                    !!contentImageUrl && (
                      <Grid
                        item
                        className={classes.contentImageGridItem}
                        key={`project-${contentIndex}-${contentImageUrl}`}
                        data-cy={`projectContentImage-isSample-${project.isSample}-${contentIndex}`}
                      >
                        <img
                          className={classes.contentImage}
                          src={contentImageUrl}
                        />
                      </Grid>
                    )
                )}
                {project.contentImageUrls.length > 5 && (
                  <Typography variant="body1">...</Typography>
                )}
              </Grid>
            </div>
          </CardActionArea>
          <CardActions className={classes.projectActions}>
            {project.isSample || project.isMypageProject ? null : (
              <>
                <Tooltip
                  title="申込済の内容をコピーする"
                  aria-label="copy"
                  placement="top"
                >
                  <IconButton
                    onClick={onClickCopyButtonHandler}
                    data-cy={`copyIcon-isSample-${project.isSample}-${project.urlCode}`}
                    size="large"
                  >
                    <FileCopy className={classes.icon} />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title="担当者に問い合わせる"
                  aria-label="add"
                  placement="top"
                >
                  <IconButton
                    aria-label="More"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={() => goMessageThread(navigate, project.urlCode)}
                    data-cy={`messageThreadIcon-isSample-${project.isSample}-${project.urlCode}`}
                    size="large"
                  >
                    <MessageIcon className={classes.icon} />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </CardActions>
        </Card>
      </Grid>
    </Grow>
  );
};

export default withStyles(styles)(DashboardProject);
