import { getLocalString } from '@common/lib/getLocalString';
import { getLocalePrice } from '@common/lib/getLocalePrice';
import GiftCardPreviewModal from '@console/components/common/GiftCardPreviewModal';
import GifteeBoxConfigMessageCardOptionModal from '@console/components/common/GifteeBoxConfigMessageCardOptionModal';
import Notes from '@console/components/project/giftConfig/Notes';
import GiftCardConfigRow from '@console/components/project/giftConfig/bulkTable/GiftCardConfigRow';
import IssueAmountRequestModal from '@console/components/project/giftConfig/bulkTable/IssueAmountRequestModal';
import GifteeBoxTemplateContentSelectionsModalContainer from '@console/containers/common/GifteeBoxTemplateContentSelectionsModalContainer';
import GiftCardPreviewContainer from '@console/containers/project/GiftCardPreviewContainer';
import GifteeBoxConfigRowContainer from '@console/containers/project/giftConfig/bulkTable/GifteeBoxConfigRowContainer';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import type {
  BulkTablePropsMappedFromState,
  BulkTablePropsMappedFromDispatch
} from '@console/containers/project/giftConfig/BulkTableContainer';
import type { LocalGifteeBoxConfig } from '@console/reducers/projects';
import type { GiftCardConfigForDisplay } from '@console/selectors/projectDetail/giftCardConfigs';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  tableContainer: {
    overflowX: 'scroll',
    whiteSpace: 'nowrap'
  },
  tableCell: {
    padding: theme.spacing(2),
    verticalAlign: 'middle'
  },
  contentContainer: {
    width: theme.spacing(45),
    whiteSpace: 'pre-line'
  },
  contentImage: {
    width: theme.spacing(9),
    verticalAlign: 'middle'
  },
  contentImageContainer: {
    marginRight: theme.spacing(2)
  },
  issueAmountContainer: {
    width: theme.spacing(12),
    whiteSpace: 'pre-line'
  },
  textField: {
    minWidth: theme.spacing(10)
  },
  button: {
    margin: theme.spacing(),
    marginRight: 0,
    padding: '5px 8px'
  },
  notes: {
    marginTop: theme.spacing(2)
  }
});

type BulkTableProps = BulkTablePropsMappedFromState &
  BulkTablePropsMappedFromDispatch &
  WithStyles<typeof styles>;

const BULK_NOTES = [
  '※審査完了前は数量確定ボタンを押すことができません。審査完了まで、いましばらくお待ちください。'
];

const BulkTable: React.FC<BulkTableProps> = ({
  giftCardConfigs,
  gifteeBoxConfigs,
  selectedGiftCardConfig,
  selectedBulkGiftCardIssueConfig,
  requestGiftCardIssueAmount,
  onChangeBulkGiftCardIssueConfigIssueAmount,
  onClickOpenGiftCardPreviewButton,
  closeProjectGiftConfigBulkTable,
  selectGifteeBoxTemplate,
  deselectGifteeBoxTemplate,
  handleAddBulkGiftCardIssueConfigsClick,
  handleBulkGiftCardIssueConfigClick,
  classes
}) => {
  const navigate = useNavigate();
  const [
    bulkGiftCardIssueConfigModalOpen,
    setBulkGiftCardIssueConfigModalOpen
  ] = useState<boolean>(false);

  const handleGiftCardModalOKClick = () => {
    requestGiftCardIssueAmount(
      selectedBulkGiftCardIssueConfig.issueAmountRequest.issueAmount,
      selectedBulkGiftCardIssueConfig.urlCode
    );
    setBulkGiftCardIssueConfigModalOpen(!bulkGiftCardIssueConfigModalOpen);
  };

  const [giftCardPreviewModalOpen, setGiftCardPreviewModalOpen] =
    useState<boolean>(false);

  const openGiftCardPreviewModal = () => {
    setGiftCardPreviewModalOpen(true);
  };

  const closeGiftCardPreviewModal = () => {
    setGiftCardPreviewModalOpen(false);
  };

  const handleOpenGiftCardPreviewButtonClick = (
    giftCardConfig: GiftCardConfigForDisplay
  ) => {
    onClickOpenGiftCardPreviewButton(giftCardConfig);
    openGiftCardPreviewModal();
  };

  const handleGiftCardPreviewModalClose = () => {
    closeGiftCardPreviewModal();
  };

  useEffect(
    () => () => {
      closeProjectGiftConfigBulkTable();
    },
    []
  );

  const [
    gifteeBoxTemplateContentSelectionsModalOpen,
    setGifteeBoxTemplateContentSelectionsModalOpen
  ] = useState(false);

  const openGifteeBoxTemplateContentSelectionsModal = (
    gifteeBoxConfig: LocalGifteeBoxConfig
  ) => {
    selectGifteeBoxTemplate(gifteeBoxConfig.gifteeBoxTemplate);
    setGifteeBoxTemplateContentSelectionsModalOpen(true);
  };

  const closeGifteeBoxTemplateContentSelectionsModal = () => {
    setGifteeBoxTemplateContentSelectionsModalOpen(false);
    deselectGifteeBoxTemplate();
  };

  const [
    gifteeBoxConfigMessageCardOptionModalOpen,
    setGifteeBoxConfigMessageCardOptionModalOpen
  ] = useState(false);
  const [
    gifteeBoxConfigMessageCardOption,
    setGifteeBoxConfigMessageCardOption
  ] = useState<LocalGifteeBoxConfig['messageCardOption']>({
    description: '',
    imageUrl: ''
  });

  const openGifteeBoxConfigMessageCardOptionModal = (
    gifteeBoxConfig: LocalGifteeBoxConfig
  ) => {
    setGifteeBoxConfigMessageCardOption(gifteeBoxConfig.messageCardOption);
    setGifteeBoxConfigMessageCardOptionModalOpen(true);
  };

  const closeGifteeBoxConfigMessageCardOptionModal = () => {
    setGifteeBoxConfigMessageCardOptionModalOpen(false);
  };

  return (
    <>
      <IssueAmountRequestModal
        open={bulkGiftCardIssueConfigModalOpen}
        toggleModalOpen={() =>
          setBulkGiftCardIssueConfigModalOpen(!bulkGiftCardIssueConfigModalOpen)
        }
        giftCardTicketConfigs={selectedGiftCardConfig?.giftCardTicketConfigs}
        handleClick={handleGiftCardModalOKClick}
        issueAmount={getLocalString(
          selectedBulkGiftCardIssueConfig.issueAmountRequest.issueAmount
        )}
        currentPriceTotal={getLocalePrice(
          selectedBulkGiftCardIssueConfig.claimEstimate.estimatedAmount
        )}
        deliveryDate={selectedBulkGiftCardIssueConfig.deliveryDate}
      />
      <div className={classes.tableContainer} data-cy="tableContainer">
        <Table className={classes.table}>
          <TableHead data-cy="tableHeader">
            <TableRow>
              <TableCell className={classes.tableCell} align="center">
                ギフトのタイプ
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                ギフト・ブランド名
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                ギフトカード
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                単価
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                数量（見込み）
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                予定金額
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                確定数量
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                確定金額
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                納品希望日
              </TableCell>
              <TableCell className={classes.tableCell} align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {!giftCardConfigs
              ? null
              : giftCardConfigs.map(giftCardConfig => (
                  <GiftCardConfigRow
                    giftCardConfig={giftCardConfig}
                    onChangeBulkGiftCardIssueConfigIssueAmount={
                      onChangeBulkGiftCardIssueConfigIssueAmount
                    }
                    handleOpenGiftCardPreviewButtonClick={
                      handleOpenGiftCardPreviewButtonClick
                    }
                    handleBulkGiftCardIssueConfigClick={handleBulkGiftCardIssueConfigClick(
                      () =>
                        setBulkGiftCardIssueConfigModalOpen(
                          !bulkGiftCardIssueConfigModalOpen
                        )
                    )}
                    handleAddBulkGiftCardIssueConfigsClick={addingGiftCardConfig =>
                      handleAddBulkGiftCardIssueConfigsClick(
                        navigate,
                        addingGiftCardConfig
                      )
                    }
                    key={`bulkTableGiftCardIssueConfigRow-${giftCardConfig.urlCode}`}
                  />
                ))}

            {!gifteeBoxConfigs
              ? null
              : gifteeBoxConfigs.map(gifteeBoxConfig => (
                  <GifteeBoxConfigRowContainer
                    gifteeBoxConfig={gifteeBoxConfig}
                    handleGifteeBoxTemplateContentSelectionsModalOpen={() =>
                      openGifteeBoxTemplateContentSelectionsModal(
                        gifteeBoxConfig
                      )
                    }
                    handleOpenGifteeBoxConfigMessageCardOptionModalOpen={() =>
                      openGifteeBoxConfigMessageCardOptionModal(gifteeBoxConfig)
                    }
                    key={gifteeBoxConfig.urlCode}
                  />
                ))}
          </TableBody>
        </Table>
      </div>
      <div className={classes.notes}>
        <Notes notes={BULK_NOTES} />
      </div>
      <GifteeBoxTemplateContentSelectionsModalContainer
        isOpen={gifteeBoxTemplateContentSelectionsModalOpen}
        handleClose={closeGifteeBoxTemplateContentSelectionsModal}
      />
      <GiftCardPreviewModal
        isOpen={giftCardPreviewModalOpen}
        onCloseHandler={handleGiftCardPreviewModalClose}
      >
        <GiftCardPreviewContainer />
      </GiftCardPreviewModal>
      <GifteeBoxConfigMessageCardOptionModal
        isOpen={gifteeBoxConfigMessageCardOptionModalOpen}
        onCloseHandler={closeGifteeBoxConfigMessageCardOptionModal}
        imageUrl={gifteeBoxConfigMessageCardOption?.imageUrl}
        description={gifteeBoxConfigMessageCardOption?.description}
      />
    </>
  );
};

export default withStyles(styles)(BulkTable);
