import {
  selectProjectDraft,
  handleProjectDraftOptionOpen,
  handleProjectDraftOptionClose,
  setSelectedProjectUrlCode
} from '@console/actions/actions';
import { copyProjectToProjectDraftThunk } from '@console/actions/thunks/copyProjectToProjectDraft';
import { createSampleProjectThunk } from '@console/actions/thunks/createSampleProject';
import { deleteProjectDraftThunk } from '@console/actions/thunks/deleteProjectDraft';
import { getGeneralAnnouncementsThunk } from '@console/actions/thunks/getGeneralAnnouncements';
import { getProjectDraftsThunk } from '@console/actions/thunks/getProjectDrafts';
import { getProjectDraftsContentsThunk } from '@console/actions/thunks/getProjectDraftsContents';
import { getProjectsThunk } from '@console/actions/thunks/getProjects';
import { getG4bMyPageProjectsUrl } from '@console/common/g4bMyPage';
import { isApiSolutionSystem } from '@console/common/solutionSystem';
import { ProjectContents } from '@console/components/MainContents';
import DashboardIndex from '@console/components/dashboard/Index';
import {
  ParentSteps,
  CampaignOverviewChildSteps
} from '@console/components/projectDraft/Root';
import {
  getProjectDraftsForDisplay,
  getProjectsForDisplay,
  getSampleProjectsForDisplay
} from '@console/selectors/index';
import moment from 'moment';
import { connect } from 'react-redux';
import type { QueryProjectUrlCode } from '@common/api/graphql/getProjectsGql';
import type {
  AppThunkAction,
  AppThunkDispatch
} from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';
import type { ProjectDraft } from '@console/reducers/selectedProjectDraft';
import type { LocalProjectForDisplay } from '@console/selectors/getProjectsForDisplay';
import type { NavigateFunction } from 'react-router';

const goProjectDraft =
  (
    navigate: NavigateFunction,
    projectDraft: ProjectDraft
  ): AppThunkAction<void> =>
  dispatch => {
    dispatch(selectProjectDraft(projectDraft));
    goProjectDraftPage(navigate, projectDraft.urlCode);
    return Promise.resolve();
  };

const goProject =
  (
    navigate: NavigateFunction,
    project: LocalProjectForDisplay
  ): AppThunkAction<void> =>
  async dispatch => {
    await dispatch(setSelectedProjectUrlCode(project.urlCode));
    if (project.isMypageProject) {
      location.href = `${getG4bMyPageProjectsUrl()}/${project.urlCode}`;
    } else {
      navigate(`/console/projects/${project.urlCode}/detail`);
    }
  };

const goMessageThread =
  (
    navigate: NavigateFunction,
    projectUrlCode: QueryProjectUrlCode
  ): AppThunkAction<void> =>
  dispatch => {
    dispatch(setSelectedProjectUrlCode(projectUrlCode));
    navigate(
      `/console/projects/${projectUrlCode}/${ProjectContents.MESSAGE_THREAD}`
    );
  };

const getProjectDrafts = (): AppThunkAction<void> => dispatch => {
  dispatch(getProjectDraftsThunk())
    .then(() => dispatch(getProjectDraftsContentsThunk()))
    .catch(() => {});
};

const getProjects = (): AppThunkAction<void> => (dispatch, getState) => {
  dispatch(getProjectsThunk()).catch(() => {});
};

const getGeneralAnnouncements = (): AppThunkAction<void> => dispatch => {
  dispatch(getGeneralAnnouncementsThunk()).catch(() => {});
};

const onClickCreateApiTestProject =
  (): AppThunkAction<void> => (dispatch, getState) => {
    const { solutionSystems } = getState();
    const apiSolutionSystem = solutionSystems.find(solutionSystem =>
      isApiSolutionSystem(solutionSystem)
    );

    const projectName = `APIテスト-${moment().format(
      'YYYY年MM月DD日-HH時mm分ss秒'
    )}`;

    dispatch(createSampleProjectThunk(apiSolutionSystem!, projectName)).catch(
      () => {}
    );
  };

const deleteProjectDraft =
  (urlCode: UrlCode): AppThunkAction<void> =>
  dispatch => {
    dispatch(deleteProjectDraftThunk(urlCode)).catch(() => {});
  };

const copyProjectToProjectDraft =
  (urlCode: UrlCode, navigate: NavigateFunction): AppThunkAction<void> =>
  (dispatch, getState) => {
    const { projects } = getState();
    const selectedProject = projects.find(
      project => project.urlCode === urlCode
    )!;

    dispatch(copyProjectToProjectDraftThunk(selectedProject))
      .then(() => {
        const { urlCode } = getState().selectedProjectDraft;
        goProjectDraftPage(navigate, urlCode);
      })
      .catch(() => {});
  };

const goProjectDraftPage = (
  navigate: NavigateFunction,
  projectDraftUrlCode: UrlCode
) =>
  navigate(
    `/console/project_drafts/${projectDraftUrlCode}/edit?parentStep=${ParentSteps.CAMPAIGN_OVERVIEW}&childStep=${CampaignOverviewChildSteps.CAMPAIGN_OVERVIEW}`
  );

const mapStateToProps = (state: AppState) => ({
  projectDraftsForDisplay: getProjectDraftsForDisplay(state),
  projectsForDisplay: getProjectsForDisplay(state),
  sampleProjectsForDisplay: getSampleProjectsForDisplay(state),
  dashboardProjectDraftAnchors: state.dashboardProjectDraftAnchors,
  projectDraftSyncStatus: state.projectDraftSyncStatus,
  distributionPartner: state.distributionPartner,
  generalAnnouncements: state.generalAnnouncements,
  isProjectCardLoading: state.loaders.isProjectCardLoading,
  isProjectDraftCardLoading: state.loaders.isProjectCardLoading
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  goProjectDraft: (navigate: NavigateFunction, projectDraft: ProjectDraft) =>
    dispatch(goProjectDraft(navigate, projectDraft)),
  goProject: (navigate: NavigateFunction, project: LocalProjectForDisplay) =>
    dispatch(goProject(navigate, project)),
  goMessageThread: (
    navigate: NavigateFunction,
    projectUrlCode: QueryProjectUrlCode
  ) => dispatch(goMessageThread(navigate, projectUrlCode)),
  getProjectDrafts: () => dispatch(getProjectDrafts()),
  getProjects: () => dispatch(getProjects()),
  getGeneralAnnouncements: () => dispatch(getGeneralAnnouncements()),
  handleProjectDraftOptionOpen: (event: React.MouseEvent, index: number) => {
    dispatch(handleProjectDraftOptionOpen(event.currentTarget, index));
  },
  handleProjectDraftOptionClose: (index: number) =>
    dispatch(handleProjectDraftOptionClose(index)),
  onClickCreateApiTestProject: () => dispatch(onClickCreateApiTestProject()),
  deleteProjectDraft: (urlCode: UrlCode) =>
    dispatch(deleteProjectDraft(urlCode)),
  copyProjectToProjectDraft: (urlCode: UrlCode, navigate: NavigateFunction) =>
    dispatch(copyProjectToProjectDraft(urlCode, navigate))
});

export type DashboardIndexPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type DashboardIndexPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(DashboardIndex);
