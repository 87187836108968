export type ActiveStep = {
  parentStep: number;
  childStep: number;
};

export const getActiveStep = (): ActiveStep => {
  const searchParams = new URLSearchParams(location.search);
  const childStep = Number(searchParams.get('childStep'));
  const parentStep = Number(searchParams.get('parentStep'));

  return { parentStep, childStep };
};
