import { ERROR_MESSAGE_FOR_PAGE_VALIDATION } from '@common/middleware/errorMessages';
import {
  validateProjectDraftFailure,
  addGiftConfigSelectedContents,
  removeGiftConfigSelectedContents,
  changeContentsBrandFilter,
  changeContentsPriceRangeFilter
} from '@console/actions/actions';
import { getContentsOfSelectedProjectDraftThunk } from '@console/actions/thunks/getContentsOfSelectedProjectDraft';
import { getContinuousContentsThunk } from '@console/actions/thunks/getContinuousContents';
import { getInitialContentsThunk } from '@console/actions/thunks/getInitialContents';
import { validateChoosableContentsSelectionThunk } from '@console/actions/thunks/validateProjectDraft/validateGiftConfig/validateContentsSelection';
import { getActiveStep } from '@console/common/getActiveStep';
import ChoosableContentsSelection, {
  SLIDER_MAX,
  SLIDER_MIN
} from '@console/components/common/contentsSelection/ChoosableContentsSelection';
import { goNextStep } from '@console/containers/projectDraft/StepHandlerContainer';
import {
  getContentsWithIsSelectedInSelectedGiftConfigInSelectedProjectDraft,
  getSelectedProjectDraftGiftConfigSelectedContents
} from '@console/selectors/getSelectedProjectDraftForDisplay';
import { connect } from 'react-redux';
import type { Brand } from '@common/api/graphql/getBrandsGql';
import type {
  AppThunkAction,
  AppThunkDispatch
} from '@console/actions/thunkType';
import type { SliderPrices } from '@console/components/common/PricesSlider';
import type { AppState } from '@console/reducers';
import type { ContentWithIsSelected } from '@console/selectors/getSelectedProjectDraftForDisplay';
import type { NavigateFunction } from 'react-router';

const handleStepNextFromParent =
  (navigate: NavigateFunction): AppThunkAction<Promise<void>> =>
  dispatch =>
    dispatch(validateChoosableContentsSelectionThunk())
      .then(() => {
        const { parentStep, childStep } = getActiveStep();
        goNextStep(navigate, parentStep, childStep);
      })
      .catch(() => {
        dispatch(
          validateProjectDraftFailure(ERROR_MESSAGE_FOR_PAGE_VALIDATION)
        );
      });

const handleContentSelect =
  (contentWithIsSelected: ContentWithIsSelected): AppThunkAction<void> =>
  (dispatch, getState) => {
    const selectedGiftConfigIndex =
      getState().selectedProjectDraft.projectJson.selectedGiftConfigIndex;

    dispatch(
      addGiftConfigSelectedContents(
        contentWithIsSelected,
        selectedGiftConfigIndex
      )
    );
  };

const handleContentDeselect =
  (contentWithIsSelected: ContentWithIsSelected): AppThunkAction<void> =>
  (dispatch, getState) => {
    const selectedGiftConfigIndex =
      getState().selectedProjectDraft.projectJson.selectedGiftConfigIndex;

    dispatch(
      removeGiftConfigSelectedContents(
        contentWithIsSelected,
        selectedGiftConfigIndex
      )
    );
  };

const onChangeBrandFilter =
  (brand: Brand | null): AppThunkAction<void> =>
  async dispatch => {
    const brands = brand ? [brand] : undefined;
    dispatch(changeContentsBrandFilter(brands));

    await dispatch(getContentsOfSelectedProjectDraftThunk()).catch(() => {});
    await dispatch(getInitialContentsThunk()).catch(() => {});
  };

const onChangePriceRangeFilter =
  (prices: SliderPrices): AppThunkAction<void> =>
  async dispatch => {
    const minPrice =
      prices.lowerPrice === SLIDER_MIN ? undefined : prices.lowerPrice;
    const maxPrice =
      prices.higherPrice === SLIDER_MAX ? undefined : prices.higherPrice;

    dispatch(changeContentsPriceRangeFilter(minPrice, maxPrice));

    await dispatch(getContentsOfSelectedProjectDraftThunk()).catch(() => {});
    await dispatch(getInitialContentsThunk()).catch(() => {});
  };

const handleScrollToBottom = (): AppThunkAction<void> => dispatch => {
  dispatch(getContinuousContentsThunk()).catch(() => {});
};

const mapStateToProps = (state: AppState) => ({
  brands: state.brands,
  contentsWithIsSelected:
    getContentsWithIsSelectedInSelectedGiftConfigInSelectedProjectDraft(state),
  selectedContents: getSelectedProjectDraftGiftConfigSelectedContents(state),
  contentsTotalCount: state.searchResult.contents.totalCount ?? 0,
  isContentsLoading: state.loaders.isContentsLoading,
  giftConfigError:
    state.errors.projectDraftGiftConfigs[
      state.selectedProjectDraft.projectJson.selectedGiftConfigIndex
    ]
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  handleStepNextFromParent: (navigate: NavigateFunction) =>
    dispatch(handleStepNextFromParent(navigate)),
  handleContentSelect: (contentWithIsSelected: ContentWithIsSelected) =>
    dispatch(handleContentSelect(contentWithIsSelected)),
  handleContentDeselect: (contentWithIsSelected: ContentWithIsSelected) =>
    dispatch(handleContentDeselect(contentWithIsSelected)),
  onChangeBrandFilter: (brand: Brand | null) =>
    dispatch(onChangeBrandFilter(brand)),
  onChangePriceRangeFilter: (prices: SliderPrices) =>
    dispatch(onChangePriceRangeFilter(prices)),
  handleScrollToBottom: () => dispatch(handleScrollToBottom())
});

export type ChoosableContentsSelectionPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type ChoosableContentsSelectionPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChoosableContentsSelection);
