import { connect } from 'react-redux';
import { matchPath } from 'react-router';
import { ProjectContents } from '../../components/MainContents';
import FloatingButton from '../../components/project/FloatingButton';
import type { AppThunkDispatch, AppThunkAction } from '../../actions/thunkType';
import type { PathParams } from '../../components/project/FloatingButton';
import type { AppState } from '../../reducers';
import type { Location, NavigateFunction } from 'react-router';

const toggleMessageThread =
  (
    navigate: NavigateFunction,
    params: PathParams,
    location: Location
  ): AppThunkAction<void> =>
  () => {
    const { urlCode } = params;
    if (isMessageThreadShown(location.pathname)) {
      navigate(
        `/console/projects/${urlCode}/${ProjectContents.PROJECT_DETAIL}`
      );
    } else {
      navigate(
        `/console/projects/${urlCode}/${ProjectContents.MESSAGE_THREAD}`
      );
    }
  };

export const isMessageThreadShown = (currentPath: string) =>
  matchPath(
    `/console/projects/:urlCode/${ProjectContents.MESSAGE_THREAD}`,
    currentPath
  );

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  toggleMessageThread: (
    navigate: NavigateFunction,
    params: PathParams,
    location: Location
  ) => dispatch(toggleMessageThread(navigate, params, location))
});

export type FloatingButtonPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type FloatingButtonPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(FloatingButton);
