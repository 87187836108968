import withStyles from '@mui/styles/withStyles';
import { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import NotFound from '../../../../common/components/NotFound';
import DrawerContainer from '../../containers/account/DrawerContainer';
import type {
  AccountPropsMappedFromDispatch,
  AccountPropsMappedFromState
} from '../../containers/account/AccountContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  content: {
    marginLeft: 200,
    marginTop: 40
  }
});

type PathParams = {
  distributionPartnerUrlCode?: string;
  distributionPartnerUserUrlCode?: string;
};

type AccountProps = AccountPropsMappedFromDispatch &
  AccountPropsMappedFromState &
  WithStyles<typeof styles>;

const Account: React.FC<React.PropsWithChildren<AccountProps>> = ({
  classes,
  children,
  distributionPartnerUser,
  distributionPartner
}) => {
  const params = useParams<PathParams>();
  const [isNotFound, setIsNotFound] = useState(false);

  useEffect(() => {
    showNotFoundIfNeeded();
  }, []);

  const showNotFoundIfNeeded = useCallback(() => {
    if (isDistributionPartnerUrlCodeInvalid()) {
      setIsNotFound(true);
    }
    if (isDistributionPartnerUserUrlCodeInvalid()) {
      setIsNotFound(true);
    }
  }, []);

  const isDistributionPartnerUrlCodeInvalid = (): boolean => {
    const { distributionPartnerUrlCode } = params;
    return (
      !!distributionPartnerUrlCode &&
      distributionPartnerUrlCode !== distributionPartner.urlCode
    );
  };

  const isDistributionPartnerUserUrlCodeInvalid = (): boolean => {
    const { distributionPartnerUserUrlCode } = params;
    return (
      !!distributionPartnerUserUrlCode &&
      distributionPartnerUserUrlCode !== distributionPartnerUser.urlCode
    );
  };

  return isNotFound ? (
    <div>
      <NotFound />
    </div>
  ) : (
    <div className={classes.content}>
      <DrawerContainer />
      {children}
    </div>
  );
};

export default withStyles(styles)(Account);
