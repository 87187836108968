import Note from '@console/components/common/Note';
import { RELATION_BETWEEN_HOST_COMPANY_AND_DP } from '@console/reducers/projectJson/hostCompany';
import { Help } from '@mui/icons-material';
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Link
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { DesktopDatePicker as DatePicker } from '@mui/x-date-pickers';
import { useNavigate } from 'react-router';
import moment from '../../../../common/lib/moment';
import StepHandlerContainer from '../../containers/projectDraft/StepHandlerContainer';
import SectionTitle from './common/SectionTitle';
import type {
  CampaignOverviewPropsMappedFromState,
  CampaignOverviewPropsMappedFromDispatch
} from '../../containers/projectDraft/CampaignOverviewContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const API_BULK_SOLUTION_SYSTEM_LINK_URL = {
  bulk: 'https://giftee.zendesk.com/hc/ja/articles/4404482808473',
  api: 'https://docs.giftee.biz/business/downloads/giftee_Business_API.pdf'
};

const styles = (theme: Theme): StyleRules =>
  createStyles({
    title: {
      marginTop: theme.spacing(3)
    },
    subtitle: {
      marginTop: theme.spacing(5)
    },
    input: {
      padding: `0.5% 0`,
      paddingRight: `3%`
    },
    radioGroup: {
      marginLeft: theme.spacing()
    },
    helpLink: {
      display: 'flex',
      alignItems: 'center'
    },
    solutionSystemGcpSelected: {
      marginLeft: theme.spacing()
    },
    hostCompanyNameTextField: {
      marginLeft: theme.spacing(5)
    },
    inputItemLabel: {
      marginBottom: theme.spacing()
    },
    dateInput: {
      width: 180
    },
    dateInputBetween: {
      margin: `${theme.spacing(3)} 3% 0 3%`
    },
    errorMessage: {
      minHeight: theme.spacing(3),
      fontSize: 14
    }
  });

type CampaignOverviewProps = CampaignOverviewPropsMappedFromState &
  CampaignOverviewPropsMappedFromDispatch &
  WithStyles<typeof styles>;

const CampaignOverview: React.FC<CampaignOverviewProps> = ({
  name,
  description,
  hostCompany,
  distributionPartnerName,
  startDateOfAd,
  endDateOfAd,
  startDateOfDistribution,
  endDateOfDistribution,
  campaignSolutionSystem,
  campaignSolutionSystemGcpSelected,
  apiBulkSolutionSystems,
  gcpSolutionSystems,
  campaignMarketingIndustry,
  marketingIndustries,
  thirdPartyService,
  onChangeName,
  onBlurName,
  onChangeDescription,
  onChangeHostCompanyRelationToDP,
  onChangeHostCompanyName,
  onChangeStartDateOfAd,
  onChangeEndDateOfAd,
  onChangeStartDateOfDistribution,
  onChangeEndDateOfDistribution,
  onChangeCampaignSolutionSystem,
  onChangeCampaignSolutionSystemGcp,
  onChangeCampaignSolutionSystemGcpSelected,
  onChangeMarketingIndustry,
  onChangeNameOfThirdPartyService,
  onChangeUrlOfThirdPartyService,
  onChangeDescriptionOfThirdPartyService,
  handleStepNextFromParent,
  classes,
  errors
}) => {
  const navigate = useNavigate();

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="flex-start"
      className={classes.root}
    >
      <Grid item xs={12} className={classes.title}>
        <Typography variant="h6" color="textPrimary">
          新規お申込み
        </Typography>
      </Grid>

      <Grid item xs={12} className={classes.subtitle}>
        <SectionTitle text="利用サービスを選択してください" />
      </Grid>

      <Grid item xs={12} className={classes.input}>
        <FormControl
          variant="standard"
          required
          className={classes.formControl}
          fullWidth
          error={!!errors.projectDraftCampaignOverview.campaignSolutionSystem}
        >
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.inputItemLabel}
          >
            利用サービス
          </Typography>
          <RadioGroup
            aria-label="campaignApiBulkSolutionSystem"
            className={classes.radioGroup}
            value={campaignSolutionSystem}
            onChange={onChangeCampaignSolutionSystem}
          >
            {apiBulkSolutionSystems.map((apiBulkSolutionSystem, i) => (
              <Grid container alignItems="center" key={i}>
                <Grid item>
                  <FormControlLabel
                    name="campaignApiBulkSolutionSystem"
                    control={<Radio color="primary" />}
                    label={apiBulkSolutionSystem.name}
                    value={apiBulkSolutionSystem.urlCode}
                    data-cy={`apiBulkSolutionSystem_${i}`}
                  />
                </Grid>
                {!!API_BULK_SOLUTION_SYSTEM_LINK_URL[
                  apiBulkSolutionSystem.solutionSystemIdentifier
                ] ? (
                  <Grid item>
                    <Link
                      href={
                        API_BULK_SOLUTION_SYSTEM_LINK_URL[
                          apiBulkSolutionSystem.solutionSystemIdentifier
                        ]
                      }
                      variant="body1"
                      target="_blank"
                      rel="noopener"
                      className={classes.helpLink}
                      data-cy="messageCardHelpLink"
                      underline="hover"
                    >
                      <Help />
                      詳細はこちら
                    </Link>
                  </Grid>
                ) : null}
              </Grid>
            ))}
          </RadioGroup>
          <Grid container alignItems="center">
            <Grid item>
              <FormControlLabel
                name="campaignGcpSolutionSystemGcpSelected"
                control={
                  <Radio
                    className={classes.solutionSystemGcpSelected}
                    checked={campaignSolutionSystemGcpSelected}
                    color="primary"
                    value={true}
                    onChange={onChangeCampaignSolutionSystemGcpSelected}
                    data-cy="gcpSolutionSystem"
                  />
                }
                label="giftee Plus Solutions（※）"
              />
            </Grid>
            <Grid item>
              <Link
                href="https://giftee.zendesk.com/hc/ja/articles/4404868601241"
                variant="body1"
                target="_blank"
                rel="noopener"
                className={classes.helpLink}
                data-cy="messageCardHelpLink"
                underline="hover"
              >
                <Help />
                詳細はこちら
              </Link>
            </Grid>
          </Grid>
          <div className={classes.hostCompanyNameTextField}>
            <Select
              variant="standard"
              value={campaignSolutionSystem}
              onChange={e => onChangeCampaignSolutionSystemGcp(e.target.value)}
              name="campaignSolutionSystem"
              inputProps={{
                id: 'solution-system-required'
              }}
              className={classes.pulldownGroup}
              fullWidth
              disabled={!campaignSolutionSystemGcpSelected}
              data-cy="gcpSolutionSystemList"
            >
              {gcpSolutionSystems.map((solutionSystem, i) => (
                <MenuItem value={solutionSystem.urlCode} key={i}>
                  {solutionSystem.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              ※のついているサービスは別途システム利用料がかかります。
            </FormHelperText>
          </div>
          <Typography
            id="errorMessageForEndCampaignSolutionSystem"
            className={classes.errorMessage}
            color="error"
          >
            {errors.projectDraftCampaignOverview.campaignSolutionSystem}
          </Typography>
        </FormControl>
        <Note>
          ※「リアルタイム発行（API利用）」と「giftee Plus
          Solutions」のご利用には弊社内の審査がございます。
          <br />
          審査結果次第では、お手続きのご連絡またはご利用をお断りする場合がございますのであらかじめご了承ください。
        </Note>
      </Grid>

      <Grid item xs={12} className={classes.subtitle}>
        <SectionTitle text="利用概要を入力してください" />
      </Grid>
      <Grid item xs={12} className={classes.input}>
        <FormControl variant="standard" fullWidth>
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.inputItemLabel}
          >
            キャンペーン主催企業
          </Typography>
          <RadioGroup
            aria-label="relationBetweenHostCompanyAndDP"
            className={classes.radioGroup}
            value={hostCompany.relationToDP}
            onChange={onChangeHostCompanyRelationToDP}
          >
            <FormControlLabel
              name="relationBetweenHostCompanyAndDP"
              control={<Radio color="primary" />}
              label={distributionPartnerName}
              value={RELATION_BETWEEN_HOST_COMPANY_AND_DP.EQUAL}
              data-cy="hostCompanyEqualDP"
            />
            <FormControlLabel
              name="relationBetweenHostCompanyAndDP"
              control={<Radio color="primary" />}
              label="キャンペーン主催企業が上記と異なる場合は下記に企業名をご入力ください。"
              value={RELATION_BETWEEN_HOST_COMPANY_AND_DP.OTHER}
              data-cy="hostCompanyRelationToOther"
            />
          </RadioGroup>
          <div className={classes.hostCompanyNameTextField}>
            <TextField
              variant="standard"
              required
              label="企業名"
              helperText="※キャンペーンを主催する企業を入力してください。（例）株式会社ギフティ（150文字以内）"
              fullWidth
              error={!!errors.projectDraftCampaignOverview.hostCompany}
              inputProps={{ maxLength: 150 }}
              disabled={
                hostCompany.relationToDP ===
                RELATION_BETWEEN_HOST_COMPANY_AND_DP.EQUAL
              }
              value={hostCompany.name || ''}
              onChange={onChangeHostCompanyName}
              data-cy="hostCompanyName"
            />
            <Typography
              id="errorMessageForHostCompanyName"
              className={classes.errorMessage}
              color="error"
            >
              {errors.projectDraftCampaignOverview.hostCompany}
            </Typography>
          </div>
        </FormControl>
      </Grid>
      <Grid item xs={12} className={classes.input}>
        <TextField
          variant="standard"
          required
          id="name"
          name="name"
          label="キャンペーン名"
          helperText="※ご請求書に記載する内容となります。（例）新規入会キャンペーン（120字以内）"
          value={name}
          onChange={onChangeName}
          onBlur={onBlurName}
          fullWidth
          error={!!errors.projectDraftCampaignOverview.name}
          inputProps={{ maxLength: 120 }}
        />
        <Typography
          id="errorMessageForName"
          className={classes.errorMessage}
          color="error"
        >
          {errors.projectDraftCampaignOverview.name}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.input}>
        <TextField
          variant="standard"
          required
          id="description"
          name="description"
          label="キャンペーン内容"
          helperText={
            '※配布対象者、ならびに配布条件（先着/抽選/全員プレゼントなど）、ご利用されるギフト、件数を出来るだけ詳しく記載して下さい。\n（例）期間中に新規入会されたお客様から、抽選で1000名様に〇〇ギフト1000円分をプレゼント。（500字以内）'
          }
          multiline
          value={description}
          onChange={onChangeDescription}
          fullWidth
          inputProps={{ maxLength: 500 }}
          error={!!errors.projectDraftCampaignOverview.description}
          data-cy="campaignDescription"
        />
        <Typography
          id="errorMessageForDescription"
          className={classes.errorMessage}
          color="error"
        >
          {errors.projectDraftCampaignOverview.description}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.input}>
        <FormControl
          variant="standard"
          required
          className={classes.formControl}
          fullWidth
          error={
            !!errors.projectDraftCampaignOverview.campaignMarketingIndustry
          }
        >
          <TextField
            variant="standard"
            select
            label="キャンペーン実施業界"
            value={campaignMarketingIndustry}
            onChange={onChangeMarketingIndustry}
            helperText="※キャンペーン対象の貴社サービスが属する業界を選んでください。"
            name="campaignMarketingIndustry"
            inputProps={{
              id: 'marketing-industry-required'
            }}
            fullWidth
            multiline
            className={classes.selectEmpty}
            data-cy="campaignMarketingIndustry"
            error={
              !!errors.projectDraftCampaignOverview.campaignMarketingIndustry
            }
          >
            {marketingIndustries.map((marketingIndustry, i) => (
              <MenuItem value={marketingIndustry.urlCode} key={i}>
                {marketingIndustry.name}
              </MenuItem>
            ))}
          </TextField>
          <Typography
            id="errorMessageForEndCampaignMarketingIndustry"
            className={classes.errorMessage}
            color="error"
          >
            {errors.projectDraftCampaignOverview.campaignMarketingIndustry}
          </Typography>
        </FormControl>
      </Grid>
      <Grid container alignItems="flex-start">
        <Grid item md={12} lg={6} container alignItems="center">
          <Grid item md={12} container>
            <DatePicker
              label="掲載開始日"
              value={startDateOfAd !== null ? moment(startDateOfAd) : null}
              minDate={moment().add(1, 'd')}
              maxDate={moment().add(1, 'd').add(1, 'y')}
              className={classes.dateInput}
              onChange={date => onChangeStartDateOfAd(date)}
              slotProps={{
                textField: {
                  id: 'startDateOfAd',
                  required: true,
                  error:
                    !!errors.projectDraftCampaignOverview.startDateOfAd ||
                    !!errors.projectDraftCampaignOverview.dateConsistencyOfAd,
                  variant: 'standard'
                }
              }}
            />
            <Typography
              variant="body2"
              color="primary"
              className={classes.dateInputBetween}
            >
              〜
            </Typography>
            <DatePicker
              label="掲載終了日"
              value={endDateOfAd !== null ? moment(endDateOfAd) : null}
              minDate={moment().add(1, 'd')}
              maxDate={
                !!startDateOfAd
                  ? moment(startDateOfAd).add(1, 'y')
                  : moment().add(1, 'y')
              }
              className={classes.dateInput}
              onChange={date => onChangeEndDateOfAd(date)}
              slotProps={{
                textField: {
                  id: 'endDateOfAd',
                  required: true,
                  error:
                    !!errors.projectDraftCampaignOverview.endDateOfAd ||
                    !!errors.projectDraftCampaignOverview.dateConsistencyOfAd,
                  variant: 'standard'
                }
              }}
            />
          </Grid>
          <Grid item md={12}>
            <Typography variant="body2" color="textSecondary">
              ※1年以内の日付を入力してください。
            </Typography>
            <div className={classes.errorMessage}>
              <Typography
                id="errorMessageForStartDateOfAd"
                variant="body2"
                color="error"
              >
                {errors.projectDraftCampaignOverview.startDateOfAd}
              </Typography>
              <Typography
                id="errorMessageForEndDateOfAd"
                variant="body2"
                color="error"
              >
                {errors.projectDraftCampaignOverview.endDateOfAd}
              </Typography>
              <Typography
                id="errorMessageForDateConsistencyOfAd"
                variant="body2"
                color="error"
              >
                {errors.projectDraftCampaignOverview.dateConsistencyOfAd}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid item md={12} lg={6} container alignItems="center">
          <Grid item md={12} container>
            <DatePicker
              label="ギフト配布開始日"
              value={
                startDateOfDistribution !== null
                  ? moment(startDateOfDistribution)
                  : null
              }
              minDate={moment().add(1, 'd')}
              maxDate={moment().add(1, 'd').add(1, 'y')}
              className={classes.dateInput}
              onChange={date =>
                onChangeStartDateOfDistribution(
                  date
                    ? date.set({
                        hour: 0,
                        minute: 0,
                        second: 0,
                        millisecond: 0
                      })
                    : null
                )
              }
              slotProps={{
                textField: {
                  variant: 'standard',
                  required: true,
                  id: 'startDateOfDistribution',
                  error:
                    !!errors.projectDraftCampaignOverview
                      .startDateOfDistribution ||
                    !!errors.projectDraftCampaignOverview
                      .dateConsistencyOfDistribution
                }
              }}
            />
            <Typography
              variant="body2"
              color="primary"
              className={classes.dateInputBetween}
            >
              〜
            </Typography>
            <DatePicker
              label="ギフト配布終了日"
              value={
                endDateOfDistribution !== null
                  ? moment(endDateOfDistribution)
                  : null
              }
              minDate={moment().add(1, 'd')}
              maxDate={
                !!startDateOfDistribution
                  ? moment(startDateOfDistribution).add(1, 'y')
                  : moment().add(1, 'y')
              }
              className={classes.dateInput}
              onChange={date =>
                onChangeEndDateOfDistribution(
                  date
                    ? date.set({
                        hour: 0,
                        minute: 0,
                        second: 0,
                        millisecond: 0
                      })
                    : null
                )
              }
              slotProps={{
                textField: {
                  variant: 'standard',
                  required: true,
                  id: 'endDateOfDistribution',
                  error:
                    !!errors.projectDraftCampaignOverview
                      .endDateOfDistribution ||
                    !!errors.projectDraftCampaignOverview
                      .dateConsistencyOfDistribution
                }
              }}
            />
          </Grid>
          <Grid item md={12}>
            <Typography variant="body2" color="textSecondary">
              ※1年以内の日付を入力してください。
            </Typography>
            <div className={classes.errorMessage}>
              <Typography
                id="errorMessageForStartDateOfDistribution"
                color="error"
                variant="body2"
              >
                {errors.projectDraftCampaignOverview.startDateOfDistribution}
              </Typography>
              <Typography
                id="errorMessageForEndDateOfDistribution"
                color="error"
                variant="body2"
              >
                {errors.projectDraftCampaignOverview.endDateOfDistribution}
              </Typography>
              <Typography
                id="errorMessageForDateConsistencyOfDistribution"
                color="error"
                variant="body2"
              >
                {
                  errors.projectDraftCampaignOverview
                    .dateConsistencyOfDistribution
                }
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.subtitle}>
        <SectionTitle
          text="キャンペーン対象の貴社サービスについて記載してください"
          helperText="※任意（該当のサービスがある場合は、ご記入ください）"
        />
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={12} sm={6} className={classes.input}>
          <TextField
            variant="standard"
            id="nameOfThirdPartyService"
            name="nameOfThirdPartyService"
            label="サービス名"
            value={thirdPartyService.name}
            onChange={onChangeNameOfThirdPartyService}
            fullWidth
            helperText="（例）○○アプリ"
            inputProps={{ maxLength: 255 }}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.input}>
          <TextField
            variant="standard"
            id="urlOfThirdPartyService"
            name="urlOfThirdPartyService"
            value={thirdPartyService.url}
            label="サービスサイトURL"
            onChange={onChangeUrlOfThirdPartyService}
            helperText="（例）https://xxxxx"
            fullWidth
            inputProps={{ maxLength: 255 }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={12} className={classes.input}>
          <TextField
            variant="standard"
            id="descriptionOfThirdPartyService"
            name="descriptionOfThirdPartyService"
            value={thirdPartyService.description}
            label="サービス内容"
            helperText="（例）会員限定で、各店舗のおトクな情報をゲットしたり、商品購入で貯まったポイントを交換できるアプリ。"
            onChange={onChangeDescriptionOfThirdPartyService}
            fullWidth
            inputProps={{ maxLength: 255 }}
          />
        </Grid>
      </Grid>

      <StepHandlerContainer
        isBackButtonDisabled={true}
        handleStepNextFromParent={() => handleStepNextFromParent(navigate)}
      />
    </Grid>
  );
};

export default withStyles(styles)(CampaignOverview);
