import { gql } from '@apollo/client';

import { internalApiAppClient } from './apolloClient';
import { FRAGMENT_SOLUTION_SYSTEM } from './fragments/solutionSystem';
import { operationNames } from './index';
import type { FetchResult } from '@apollo/client';

const getSolutionSystemsGql = gql`
  ${FRAGMENT_SOLUTION_SYSTEM}
  query ${operationNames.GET_SOLUTION_SYSTEMS}{
    solutionSystems {
      ...SolutionSystemFields
    }
  }
`;

export const GCP_SOLUTION_SYSTEM_IDENTIFIERS = [
  'gcp_twitter_instant_win',
  'gcp_line_instant_win_user_profile_acquisition',
  'gcp_line_instant_win_retention',
  'gcp_line_instant_win',
  'gcp_sms_instant_win',
  'gcp_common_url_line',
  'gcp_common_url_sms',
  'gcp_unique_url_questionnaire',
  'gcp_common_url_questionnaire',
  'gcp_lottery_egift',
  'gcp_serial_code_authentication',
  'gcp_twitter_send1_get1',
  'gcp_must_buy',
  'gcp_direct',
  'gcp_incentive_platform',
  'pont',
  'egiftcard',
  'gcp_mission',
  'gcp_lottery_api',
  'pointbase',
  'ggt',
  'gcp_giftee_reward_suite'
] as const;

type BulkSolutionSystemIdentifier = 'bulk';
type ApiSolutionSystemIdentifier = 'api';
export type GcpSolutionSystemIdentifier =
  (typeof GCP_SOLUTION_SYSTEM_IDENTIFIERS)[number];

type SolutionSystemIdentifier =
  | BulkSolutionSystemIdentifier
  | ApiSolutionSystemIdentifier
  | GcpSolutionSystemIdentifier;

export type SolutionSystem = {
  name: string;
  solutionSystemIdentifier: SolutionSystemIdentifier;
  urlCode: string;
};

export type SolutionSystems = SolutionSystem[];

export type GetSolutionSystemsResponse = FetchResult<{
  solutionSystems: SolutionSystems;
}>;

export const getSolutionSystems = (): Promise<GetSolutionSystemsResponse> =>
  internalApiAppClient.query({
    query: getSolutionSystemsGql
  });
