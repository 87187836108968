import {
  updateAddingBulkGiftCardIssueConfigRequestedIssueAmount,
  updateAddingBulkGiftCardIssueConfigDesiredDeliveryDate,
  addAddingBulkGiftCardIssueConfig,
  removeAddingBulkGiftCardIssueConfig,
  validateProjectAddingBulkGiftCardIssueConfigsTotalSuccess
} from '@console/actions/actions';
import { addBulkGiftCardIssueConfigsThunk } from '@console/actions/thunks/addBulkGiftCardIssueConfigs';
import { getBulkIssueConfigsClaimEstimateThunk } from '@console/actions/thunks/getBulkIssueConfigsClaimEstimate';
import { getLoginUserThunk } from '@console/actions/thunks/getLoginUser';
import { getProjectDetailThunk } from '@console/actions/thunks/getProjectDetail';
import { validateProjectAddingBulkGiftCardIssueConfigsThunk } from '@console/actions/thunks/validateProjectAddingBulkGiftCardIssueConfigs';
import { goProjectGiftConfig } from '@console/common/movePages';
import AddingBulkGiftCardIssueConfigsTable from '@console/components/project/giftCardConfigs/bulkGiftCardIssueConfigs/new/AddingBulkGiftCardIssueConfigsTable';
import { getCreditLimitBalance } from '@console/selectors/distributionPartner';
import {
  getAddingBulkGiftCardIssueConfigsTotal,
  getAddingBulkGiftCardIssueAmountTotal
} from '@console/selectors/edit/project/giftCardConfigs/addingBulkGiftCardIssueConfigs';
import { getHasProjectAddingBulkGiftCardIssueConfigsError } from '@console/selectors/errors';
import { getSelectedGiftCardConfig } from '@console/selectors/projectDetail/giftCardConfigs';
import { connect } from 'react-redux';
import type {
  AppThunkDispatch,
  AppThunkAction
} from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';
import type { Moment } from 'moment';
import type { NavigateFunction } from 'react-router';

const getClaimEstimateBasedOnTotalPriceOfIssueConfigs =
  (): AppThunkAction<void> => (dispatch, getState) => {
    const totalPrice = getAddingBulkGiftCardIssueConfigsTotal(getState());
    dispatch(getBulkIssueConfigsClaimEstimateThunk(totalPrice)).catch(() => {});
  };

const handleAddingBulkGiftCardIssueConfigRequestedIssueAmountBlur =
  (): AppThunkAction<void> => dispatch => {
    dispatch(getClaimEstimateBasedOnTotalPriceOfIssueConfigs());
  };

const handleRemoveAddingBulkGiftCardIssueConfigButtonClick =
  (addingBulkGiftCardIssueConfigIndex: number): AppThunkAction<void> =>
  dispatch => {
    dispatch(
      removeAddingBulkGiftCardIssueConfig(addingBulkGiftCardIssueConfigIndex)
    );
    dispatch(getClaimEstimateBasedOnTotalPriceOfIssueConfigs());
  };

const handleAddBulkGiftCardIssueConfigsButtonClick =
  (navigate: NavigateFunction): AppThunkAction<void> =>
  (dispatch, getState) => {
    const state = getState();
    const { selectedGiftCardConfigUrlCode } = state;
    const { addingBulkGiftCardIssueConfigs } =
      state.edits.project.giftCardConfigs;
    if (selectedGiftCardConfigUrlCode) {
      dispatch(validateProjectAddingBulkGiftCardIssueConfigsThunk())
        .then(() =>
          dispatch(
            addBulkGiftCardIssueConfigsThunk(
              selectedGiftCardConfigUrlCode,
              addingBulkGiftCardIssueConfigs
            )
          )
        )
        .then(() => {
          const { selectedProjectUrlCode } = getState();
          dispatch(getProjectDetailThunk(selectedProjectUrlCode));
          dispatch(goGiftConfig(navigate));

          if (getCreditLimitBalance(state) !== null) {
            // creditLimitBalance が更新されるので再取得
            dispatch(getLoginUserThunk());
          }
        })
        .catch(() => {});
    }
  };

const handleBackButtonClick =
  (navigate: NavigateFunction): AppThunkAction<void> =>
  dispatch => {
    dispatch(goGiftConfig(navigate));
  };

const goGiftConfig =
  (navigate: NavigateFunction): AppThunkAction<void> =>
  (_dispatch, getState) => {
    const { selectedProjectUrlCode } = getState();
    if (!!selectedProjectUrlCode) {
      goProjectGiftConfig(navigate, selectedProjectUrlCode);
    }
  };

const mapStateToProps = (state: AppState) => ({
  giftCardConfig: getSelectedGiftCardConfig(state),
  addingBulkGiftCardIssueConfigs:
    state.edits.project.giftCardConfigs.addingBulkGiftCardIssueConfigs,
  addingBulkGiftCardIssueAmountTotal:
    getAddingBulkGiftCardIssueAmountTotal(state),
  addingBulkGiftCardIssueConfigsTotal:
    getAddingBulkGiftCardIssueConfigsTotal(state),
  claimEstimate: state.claimEstimate,
  projectAddingBulkGiftCardIssueConfigsErrors:
    state.errors.projectAddingBulkGiftCardIssueConfigs,
  hasValidatorError: getHasProjectAddingBulkGiftCardIssueConfigsError(state)
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  handleAddingBulkGiftCardIssueConfigRequestedIssueAmountChange: (
    event: GenericChangeEvent<string>,
    addingBulkGiftCardIssueConfigIndex: number
  ) => {
    dispatch(
      updateAddingBulkGiftCardIssueConfigRequestedIssueAmount(
        event.target.value,
        addingBulkGiftCardIssueConfigIndex
      )
    );
    dispatch(validateProjectAddingBulkGiftCardIssueConfigsTotalSuccess());
  },
  handleAddingBulkGiftCardIssueConfigDesiredDeliveryDateChange: (
    desiredDeliveryDate: Moment | null,
    addingBulkGiftCardIssueConfigIndex: number
  ) =>
    dispatch(
      updateAddingBulkGiftCardIssueConfigDesiredDeliveryDate(
        desiredDeliveryDate,
        addingBulkGiftCardIssueConfigIndex
      )
    ),
  handleAddAddingBulkGiftCardIssueConfigButtonClick: () =>
    dispatch(addAddingBulkGiftCardIssueConfig()),
  handleRemoveAddingBulkGiftCardIssueConfigButtonClick: (
    addingBulkGiftCardIssueConfigIndex: number
  ) => {
    dispatch(
      handleRemoveAddingBulkGiftCardIssueConfigButtonClick(
        addingBulkGiftCardIssueConfigIndex
      )
    );
    dispatch(validateProjectAddingBulkGiftCardIssueConfigsTotalSuccess());
  },
  handleAddingBulkGiftCardIssueConfigRequestedIssueAmountBlur: () =>
    dispatch(handleAddingBulkGiftCardIssueConfigRequestedIssueAmountBlur()),
  handleAddBulkGiftCardIssueConfigsButtonClick: (navigate: NavigateFunction) =>
    dispatch(handleAddBulkGiftCardIssueConfigsButtonClick(navigate)),
  handleBackButtonClick: (navigate: NavigateFunction) =>
    dispatch(handleBackButtonClick(navigate)),
  handleValidateProjectAddingBulkGiftCardIssueConfigs: () =>
    dispatch(validateProjectAddingBulkGiftCardIssueConfigsThunk())
});

export type AddingBulkGiftCardIssueConfigsTablePropsMappedFromState =
  ReturnType<typeof mapStateToProps>;
export type AddingBulkGiftCardIssueConfigsTablePropsMappedFromDispatch =
  ReturnType<typeof mapDispatchToProps>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddingBulkGiftCardIssueConfigsTable);
