import ErrorBoundary from '@common/components/ErrorBoundary';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { composeWithDevTools } from '@redux-devtools/extension';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import theme from '../../common/lib/theme';
import AppContainer from './containers/AppContainer';
import validator from './middleware/validator/validator';
import reducer from './reducers';
import type { Theme } from '@mui/material/styles';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// TODO: サニタイズする変数を決める
// TODO: Production環境の扱いを入れる
const options = {
  stateSanitizer: state => state
};

const composeEnhancers = composeWithDevTools(options);

const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(thunk, validator))
);

const renderConsole = () => {
  const container = document.getElementById('root');
  if (container) {
    const root = createRoot(container);
    root.render(
      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale="ja"
              dateFormats={{
                month: 'MM月',
                year: 'YYYY年',
                fullDate: 'YYYY年MM月DD日',
                keyboardDate: 'YYYY年MM月DD日'
              }}
            >
              <ErrorBoundary>
                <Router>
                  <AppContainer />
                </Router>
              </ErrorBoundary>
            </LocalizationProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </Provider>
    );
  }
};

export default renderConsole;
