import ScrollRestoration from '@common/components/ScrollRestoration';
import { getG4bMyPageContentsUrl } from '@console/common/g4bMyPage';
import RedirectToExternalUrl from '@console/components/common/RedirectToExternalUrl';
import ProjectAPIIssueDetail from '@console/components/project/APIIssueDetail';
import ProjectAPIIpRestrictionContainer from '@console/containers/project/APIIpRestrictionContainer';
import ProjectAPIIssueReportsContainer from '@console/containers/project/APIIssueReportsContainer';
import ProjectBillingContainer from '@console/containers/project/BillingContainer';
import ProjectBrandCheckContainer from '@console/containers/project/BrandCheckContainer';
import ProjectDeliveryContainer from '@console/containers/project/DeliveryContainer';
import ProjectDetailContainer from '@console/containers/project/DetailContainer';
import ProjectGiftConfigContainer from '@console/containers/project/GiftConfigContainer';
import ProjectLayoutContainer from '@console/containers/project/ProjectLayoutContainer';
import ProjectSelectionReportContainer from '@console/containers/project/SelectionReportContainer';
import ProjectBulkGiftCardIssueConfigsNewContainer from '@console/containers/project/giftCardConfigs/bulkGiftCardIssueConfigs/NewContainer';
import ProjectGifteeBoxExchangeReportContainer from '@console/containers/project/gifteeBoxConfigs/ExchangeReportContainer';
import ProjectBulkGifteeBoxIssueConfigsNewContainer from '@console/containers/project/gifteeBoxConfigs/bulkGifteeBoxIssueConfigs/NewContainer';
import Grid from '@mui/material/Grid';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router';
import {
  isFeatureEnable,
  FeatureTypes
} from '../../../common/lib/featureToggle';
import DistributionPartnerContainer from '../containers/account/DistributionPartnerContainer';
import DistributionPartnerEditContainer from '../containers/account/DistributionPartnerEditContainer';
import DistributionPartnerUserContainer from '../containers/account/DistributionPartnerUserContainer';
import DistributionPartnerUserEditContainer from '../containers/account/DistributionPartnerUserEditContainer';
import DashboardIndexContainer from '../containers/dashboard/IndexContainer';
import MessageThreadContainer from '../containers/project/MessageThreadContainer';
import ProjectDraftEditContainer from '../containers/projectDraft/EditContainer';
import type {
  MainContentsPropsMappedFromState,
  MainContentsPropsMappedFromDispatch
} from '../containers/MainContentsContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    content: {
      backgroundColor: theme.palette.background.console,
      height: '100vh',
      padding: '0 3%',
      // TODO: theme.overrides.MuiAppbar.root.height から動的に取りたい
      paddingTop: 80,
      overflow: 'auto'
    },
    menuIcon: {
      padding: theme.spacing()
    }
  });

type MainContentsProps = MainContentsPropsMappedFromState &
  MainContentsPropsMappedFromDispatch &
  WithStyles<typeof styles>;

export const enum ProjectContents {
  PROJECT_DETAIL = 'detail',
  GIFT_CONFIG = 'gift_config',
  GIFT_CARD_CONFIGS = 'gift_card_configs',
  BULK_GIFT_CARD_ISSUE_CONFIGS = 'bulk_gift_card_issue_configs',
  GIFTEE_BOX_CONFIGS = 'giftee_box_configs',
  BULK_GIFTEE_BOX_ISSUE_CONFIGS = 'bulk_giftee_box_issue_configs',
  BRAND_CHECK = 'brand_check',
  DELIVERY = 'delivery',
  BILLING = 'billing',
  MESSAGE_THREAD = 'message_threads',
  API_ISSUE_DETAIL = 'api_issue_detail',
  API_IP_RESTRICTION = 'allowed_ips',
  API_ISSUE_REPORTS = 'issue_reports',
  SELECTION_REPORT = 'selection_report',
  EXCHANGE_REPORT = 'exchange_report'
}

export const enum AccountContents {
  DISTRIBUTION_PARTNERS = 'distribution_partners',
  USERS = 'users'
}

const SCROLL_RESTORATION_BASE = 'scrollRestorationBase';

const MainContents: React.FC<MainContentsProps> = ({
  distributionPartner,
  classes
}) => (
  <>
    <ScrollRestoration baseElementId={SCROLL_RESTORATION_BASE} />
    <Grid className={classes.content} id={SCROLL_RESTORATION_BASE}>
      <Routes>
        <Route
          path="/console/project_drafts/:urlCode/*"
          element={<ProjectDraftEditContainer />}
        />
        {!distributionPartner.inBusiness ? null : (
          <Route
            path={`/console/projects/:urlCode/${ProjectContents.PROJECT_DETAIL}`}
            element={
              <ProjectLayoutContainer>
                <ProjectDetailContainer />
              </ProjectLayoutContainer>
            }
          />
        )}
        {!distributionPartner.inBusiness ? null : (
          <Route
            path={`/console/projects/:urlCode/${ProjectContents.GIFT_CONFIG}`}
            element={
              <ProjectLayoutContainer>
                <ProjectGiftConfigContainer />
              </ProjectLayoutContainer>
            }
          />
        )}
        {!distributionPartner.inBusiness ? null : (
          <Route
            path={`/console/projects/:urlCode/${ProjectContents.GIFT_CARD_CONFIGS}/:giftCardConfigUrlCode/${ProjectContents.BULK_GIFT_CARD_ISSUE_CONFIGS}/new`}
            element={
              <ProjectLayoutContainer>
                <ProjectBulkGiftCardIssueConfigsNewContainer />
              </ProjectLayoutContainer>
            }
          />
        )}
        {!distributionPartner.inBusiness ? null : (
          <Route
            path={`/console/projects/:urlCode/${ProjectContents.GIFTEE_BOX_CONFIGS}/:gifteeBoxConfigUrlCode/${ProjectContents.BULK_GIFTEE_BOX_ISSUE_CONFIGS}/new`}
            element={
              <ProjectLayoutContainer>
                <ProjectBulkGifteeBoxIssueConfigsNewContainer />
              </ProjectLayoutContainer>
            }
          />
        )}
        {!distributionPartner.inBusiness ? null : (
          <Route
            path={`/console/projects/:urlCode/${ProjectContents.BRAND_CHECK}`}
            element={
              <ProjectLayoutContainer>
                <ProjectBrandCheckContainer />
              </ProjectLayoutContainer>
            }
          />
        )}
        {!distributionPartner.inBusiness ? null : (
          <Route
            path={`/console/projects/:urlCode/${ProjectContents.DELIVERY}`}
            element={
              <ProjectLayoutContainer>
                <ProjectDeliveryContainer />
              </ProjectLayoutContainer>
            }
          />
        )}
        {!distributionPartner.inBusiness ? null : (
          <Route
            path={`/console/projects/:urlCode/${ProjectContents.BILLING}`}
            element={
              <ProjectLayoutContainer>
                <ProjectBillingContainer />
              </ProjectLayoutContainer>
            }
          />
        )}
        {!distributionPartner.inBusiness ? null : (
          <Route
            path={`/console/projects/:urlCode/${ProjectContents.API_ISSUE_DETAIL}`}
            element={
              <ProjectLayoutContainer>
                <ProjectAPIIssueDetail />
              </ProjectLayoutContainer>
            }
          />
        )}
        {distributionPartner.inBusiness && (
          <Route
            path={`/console/projects/:urlCode/${ProjectContents.SELECTION_REPORT}`}
            element={
              <ProjectLayoutContainer>
                <ProjectSelectionReportContainer />
              </ProjectLayoutContainer>
            }
          />
        )}
        {distributionPartner.inBusiness && (
          <Route
            path={`/console/projects/:urlCode/${ProjectContents.GIFTEE_BOX_CONFIGS}/:gifteeBoxConfigUrlCode/${ProjectContents.EXCHANGE_REPORT}`}
            element={
              <ProjectLayoutContainer>
                <ProjectGifteeBoxExchangeReportContainer />
              </ProjectLayoutContainer>
            }
          />
        )}
        {!distributionPartner.inBusiness ? null : (
          <Route
            path={`/console/projects/:urlCode/${ProjectContents.API_IP_RESTRICTION}`}
            element={
              <ProjectLayoutContainer>
                <ProjectAPIIpRestrictionContainer />
              </ProjectLayoutContainer>
            }
          />
        )}
        {!distributionPartner.inBusiness ? null : (
          <Route
            path={`/console/projects/:urlCode/${ProjectContents.API_ISSUE_REPORTS}`}
            element={
              <ProjectLayoutContainer>
                <ProjectAPIIssueReportsContainer />
              </ProjectLayoutContainer>
            }
          />
        )}
        {!distributionPartner.inBusiness ? null : (
          <Route
            path={`/console/projects/:urlCode/${ProjectContents.MESSAGE_THREAD}`}
            element={<MessageThreadContainer />}
          />
        )}
        <Route
          path="/console/contents/:urlCode"
          element={<RedirectToExternalUrl url={getG4bMyPageContentsUrl()} />}
        />

        <Route
          path="/console/contents"
          element={<RedirectToExternalUrl url={getG4bMyPageContentsUrl()} />}
        />
        <Route
          path={`/console/${AccountContents.DISTRIBUTION_PARTNERS}/:distributionPartnerUrlCode`}
          element={<DistributionPartnerContainer />}
        />
        <Route
          path={`/console/${AccountContents.DISTRIBUTION_PARTNERS}/:distributionPartnerUrlCode/edit`}
          element={<DistributionPartnerEditContainer />}
        />
        <Route
          path={`/console/${AccountContents.USERS}/:distributionPartnerUserUrlCode`}
          element={<DistributionPartnerUserContainer />}
        />
        {isFeatureEnable(FeatureTypes.ACCOUNT) ? (
          <Route
            path={`/console/${AccountContents.USERS}/:distributionPartnerUserUrlCode/edit`}
            element={<DistributionPartnerUserEditContainer />}
          />
        ) : null}
        <Route path="/console/" element={<DashboardIndexContainer />} />
        <Route path="*" element={<Navigate to="/console/" replace />} />
      </Routes>
    </Grid>
  </>
);

export default withStyles(styles)(MainContents);
