import { HELPSITE_GIFTS_FOR_EMPLOYEE_URL } from '@console/common/constants';
import {
  getG4bMyPageBulkEntryUrl,
  getG4bMyPageImmediateDeliveryUrl
} from '@console/common/g4bMyPage';
import { Typography, Grid, Button, Link } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useNavigate } from 'react-router';
import type {
  ApplyStartPropsMappedFromState,
  ApplyStartPropsMappedFromDispatch
} from '@console/containers/dashboard/index/ApplyStartContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  root: {
    flexGrow: 1
  },
  wrapper: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.divider,
    borderRadius: 16,
    padding: theme.spacing(4, 3, 4, 3),
    margin: theme.spacing(2)
  },
  sectionTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2)
  },
  sectionDescription: {
    marginBottom: theme.spacing(2)
  },
  button: {
    fontSize: theme.typography.h6.fontSize
  }
});
type ApplyStartProps = WithStyles<typeof styles> &
  ApplyStartPropsMappedFromState &
  ApplyStartPropsMappedFromDispatch;

const ApplyStart: React.FC<ApplyStartProps> = ({
  inBusiness,
  addProject,
  classes
}) => {
  const navigate = useNavigate();

  return (
    <Grid
      container
      direction="column"
      className={classes.root}
      data-cy="ApplyStart"
    >
      <Grid item>
        <Typography variant="h6">新規のサービス利用申し込み</Typography>
      </Grid>
      <Grid item container direction="row">
        <Grid item xs className={classes.wrapper}>
          <Typography
            variant="h6"
            align="center"
            className={classes.sectionTitle}
          >
            CSV納品
          </Typography>
          <Typography variant="body2" align="center">
            ギフトのユニークURLをCSVファイルでご希望の場合、以下よりお申し込みください。
          </Typography>
          <Typography
            variant="body2"
            align="center"
            className={classes.sectionDescription}
          >
            追加の発注は申込済の案件ページより発注ください。
          </Typography>
          <Button
            href={getG4bMyPageBulkEntryUrl()}
            variant="contained"
            color="primary"
            fullWidth
            className={classes.button}
            disabled={!inBusiness}
          >
            CSV納品を申し込む
          </Button>
        </Grid>
        <Grid item xs className={classes.wrapper}>
          <Typography
            variant="h6"
            align="center"
            className={classes.sectionTitle}
          >
            API連携／giftee Plus Solutions利用
          </Typography>
          <Typography variant="body2" align="center">
            CSV納品以外の納品形態をご利用の場合、下記よりお申し込みください。
          </Typography>
          <Typography
            variant="body2"
            align="center"
            className={classes.sectionDescription}
          >
            追加の発注は申込済の案件ページより発注ください。
          </Typography>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className={classes.button}
            disabled={!inBusiness}
            onClick={() => addProject(navigate)}
            data-cy="addProjectDraft"
          >
            API連携／giftee Plus Solutionsを申し込む
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.wrapper}>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={8}>
            <Typography variant="h6" className={classes.sectionTitle}>
              従業員配布用のギフトをご検討の方へ
            </Typography>
            <Typography variant="body2">
              ご利用対象のギフトが「えらべるタイプ」のみの場合、
            </Typography>
            <Typography variant="body2">
              ご利用条件をご確認の上、ギフトを即日でご利用いただけます。
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Button
              href={getG4bMyPageImmediateDeliveryUrl()}
              variant="outlined"
              color="primary"
              disabled={!inBusiness}
              fullWidth
              className={classes.button}
            >
              利用条件を確認する
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default withStyles(styles)(ApplyStart);
