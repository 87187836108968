import { ERROR_MESSAGE_FOR_PAGE_VALIDATION } from '@common/middleware/errorMessages';
import {
  updateGiftConfigSenderName,
  updateGiftConfigMessage,
  updateGiftConfigMainVisual,
  validateProjectDraftFailure
} from '@console/actions/actions';
import { validateMessageCardConfigThunk } from '@console/actions/thunks/validateProjectDraft/validateGiftConfig/validateMessageCardConfig';
import MessageCardConfig from '@console/components/common/MessageCardConfig';
import {
  ParentSteps,
  GiftConfigChildSteps
} from '@console/components/projectDraft/Root';
import { connect } from 'react-redux';
import type {
  AppThunkAction,
  AppThunkDispatch
} from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';
import type { NavigateFunction } from 'react-router';

const onChangeSenderName =
  (senderName: string): AppThunkAction<void> =>
  (dispatch, getState) => {
    const { selectedGiftConfigIndex } =
      getState().selectedProjectDraft.projectJson;
    dispatch(updateGiftConfigSenderName(senderName, selectedGiftConfigIndex));
  };

const onChangeMessage =
  (message: string): AppThunkAction<void> =>
  (dispatch, getState) => {
    const { selectedGiftConfigIndex } =
      getState().selectedProjectDraft.projectJson;
    dispatch(updateGiftConfigMessage(message, selectedGiftConfigIndex));
  };

const toggleMainVisual =
  (urlCode: string): AppThunkAction<void> =>
  (dispatch, getState) => {
    const { selectedGiftConfigIndex } =
      getState().selectedProjectDraft.projectJson;
    const { selectedMainVisual } =
      getState().selectedProjectDraft.projectJson.giftConfigs[
        selectedGiftConfigIndex
      ];

    const updatedUrlCode = selectedMainVisual === urlCode ? '' : urlCode;
    dispatch(
      updateGiftConfigMainVisual(updatedUrlCode, selectedGiftConfigIndex)
    );
  };

const handleStepNextFromParent =
  (navigate: NavigateFunction): AppThunkAction<Promise<void>> =>
  dispatch =>
    dispatch(validateMessageCardConfigThunk())
      .then(() => {
        navigate(
          `${location.pathname}?parentStep=${ParentSteps.GIFT_CONFIG}&childStep=${GiftConfigChildSteps.GIFT_CONFIGS}`
        );
      })
      .catch(() => {
        dispatch(
          validateProjectDraftFailure(ERROR_MESSAGE_FOR_PAGE_VALIDATION)
        );
      });

const mapStateToProps = (state: AppState) => ({
  senderName:
    state.selectedProjectDraft.projectJson.giftConfigs[
      state.selectedProjectDraft.projectJson.selectedGiftConfigIndex
    ].senderName,
  message:
    state.selectedProjectDraft.projectJson.giftConfigs[
      state.selectedProjectDraft.projectJson.selectedGiftConfigIndex
    ].message,
  selectedMainVisual:
    state.selectedProjectDraft.projectJson.giftConfigs[
      state.selectedProjectDraft.projectJson.selectedGiftConfigIndex
    ].selectedMainVisual,
  giftCardMainVisuals: state.giftCardMainVisuals,
  giftConfigError:
    state.errors.projectDraftGiftConfigs[
      state.selectedProjectDraft.projectJson.selectedGiftConfigIndex
    ]
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  onChangeSenderName: (event: GenericChangeEvent<string>) =>
    dispatch(onChangeSenderName(event.target.value)),
  onChangeMessage: (event: GenericChangeEvent<string>) =>
    dispatch(onChangeMessage(event.target.value)),
  toggleMainVisual: (urlCode: string) => dispatch(toggleMainVisual(urlCode)),
  handleStepNextFromParent: (navigate: NavigateFunction) =>
    dispatch(handleStepNextFromParent(navigate))
});

export type MessageCardConfigPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type MessageCardConfigPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(MessageCardConfig);
