import ComponentLoader from '@common/components/ComponentLoader';
import getTransitionTimeout from '@common/lib/getTransitionTimeout';
import ApplyStartContainer from '@console/containers/dashboard/index/ApplyStartContainer';
import { ProjectDraftSyncStatuses } from '@console/reducers/projectDraftSyncStatus';
import AddIcon from '@mui/icons-material/Add';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router';
import ConfirmationModal from '../common/ConfirmationModal';
import MaintenanceMessage from '../common/MaintenanceMessage';
import UnapprovedMessage from '../common/UnapprovedMessage';
import DashboardProjectDraft from './index/ProjectDraft';
import DashboardProject from './index/project';
import type {
  DashboardIndexPropsMappedFromState,
  DashboardIndexPropsMappedFromDispatch
} from '@console/containers/dashboard/IndexContainer';
import type { LocalProject } from '@console/reducers/projects';
import type { ProjectJson } from '@console/reducers/selectedProjectDraft';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      padding: theme.spacing(4)
    },
    card: {
      width: theme.spacing(36),
      height: theme.spacing(25),
      borderRadius: theme.spacing(),
      boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
      textAlign: 'left'
    },
    mainTitle: {
      margin: `${theme.spacing(2)} 0`
    },
    subTitle: {
      margin: `${theme.spacing(3)} 0`
    },
    projects: {
      margin: `${theme.spacing()} 0`,
      flexBasis: 'auto'
    },
    addProject: {
      marginBottom: theme.spacing(2)
    },
    contentImageGridItem: {
      marginRight: theme.spacing(1)
    },
    contentImage: {
      width: theme.spacing(4.5),
      objectFit: 'contain'
    },
    projectDraftActionArea: {
      height: '80%'
    },
    projectGrid: {
      padding: `0 ${theme.spacing(2)} ${theme.spacing(4)}`
    },
    projectActionArea: {
      height: '80%'
    },
    projectActionAreaTitle: {
      position: 'absolute',
      top: 0,
      margin: `${theme.spacing(2)} ${theme.spacing(2)} 0`
    },
    projectActionAreaContentImages: {
      position: 'absolute',
      bottom: 0,
      marginLeft: theme.spacing(2)
    },
    projectName: {
      marginBottom: theme.spacing(1)
    },
    createApiTestProject: {
      height: '100%',
      backgroundColor: theme.palette.primary.light
    },
    createApiTestProjectIcon: {
      color: 'white'
    },
    createApiTestProjectTitle: {
      color: 'white'
    }
  });

type DashboardIndexProps = DashboardIndexPropsMappedFromState &
  DashboardIndexPropsMappedFromDispatch &
  WithStyles<typeof styles>;

type DashboardIndexState = {
  isConfirmModalOpen: boolean;
  selectedProjectDraftUrlCode: string | null;
  selectedProjectDraftName: string | null;
  selectedProjectUrlCode: string | null;
  selectedProjectName: string | null;
};

const DashboardIndex: React.FC<DashboardIndexProps> = ({
  classes,
  goProjectDraft,
  goProject,
  goMessageThread,
  projectDraftsForDisplay,
  projectsForDisplay,
  sampleProjectsForDisplay,
  projectDraftSyncStatus,
  distributionPartner,
  generalAnnouncements,
  isProjectCardLoading,
  isProjectDraftCardLoading,
  onClickCreateApiTestProject,
  getProjectDrafts,
  getProjects,
  getGeneralAnnouncements,
  deleteProjectDraft,
  copyProjectToProjectDraft
}) => {
  const navigate = useNavigate();

  const [isConfirmModalOpen, setIsConfirmModalOpen] =
    useState<DashboardIndexState['isConfirmModalOpen']>(false);
  const [selectedProjectDraftUrlCode, setSelectedProjectDraftUrlCode] =
    useState<DashboardIndexState['selectedProjectDraftUrlCode']>(null);
  const [selectedProjectDraftName, setSelectedProjectDraftName] =
    useState<DashboardIndexState['selectedProjectDraftName']>(null);
  const [selectedProjectUrlCode, setSelectedProjectUrlCode] =
    useState<DashboardIndexState['selectedProjectUrlCode']>(null);
  const [selectedProjectName, setSelectedProjectName] =
    useState<DashboardIndexState['selectedProjectName']>(null);

  useEffect(() => {
    getProjectDrafts();
    getProjects();
    getGeneralAnnouncements();
  }, []);

  const onClickDeleteProjectDraft = (
    urlCode: UrlCode,
    name: ProjectJson['name']
  ) => {
    setIsConfirmModalOpen(true);
    setSelectedProjectDraftUrlCode(urlCode);
    setSelectedProjectDraftName(name);
  };

  const onClickCopyProjectToProjectDraft = (
    urlCode: UrlCode,
    name: LocalProject['name']
  ) => {
    setIsConfirmModalOpen(true);
    setSelectedProjectUrlCode(urlCode);
    setSelectedProjectName(name);
  };

  const onCloseConfirmationModal = () => {
    setIsConfirmModalOpen(false);
    setSelectedProjectDraftUrlCode(null);
    setSelectedProjectDraftName(null);
    setSelectedProjectUrlCode(null);
    setSelectedProjectName(null);
  };

  const onOKDeleteProjectDraftConfirmationModal = () => {
    if (!!selectedProjectDraftUrlCode) {
      deleteProjectDraft(selectedProjectDraftUrlCode);

      setIsConfirmModalOpen(false);
      setSelectedProjectDraftUrlCode(null);
      setSelectedProjectDraftName(null);
    }
  };

  const onOKCopyProjectToProjectDraftConfirmationModal = () => {
    if (!!selectedProjectUrlCode) {
      copyProjectToProjectDraft(selectedProjectUrlCode, navigate);

      setIsConfirmModalOpen(false);
      setSelectedProjectUrlCode(null);
      setSelectedProjectName(null);
    }
  };

  const getConfirmationModal = () =>
    !!selectedProjectDraftUrlCode
      ? getDeleteProjectDraftConfirmationModal()
      : !!selectedProjectUrlCode
        ? getCopyProjectToProjectDraftConfirmationModal()
        : null;

  const getDeleteProjectDraftConfirmationModal = () => (
    <ConfirmationModal
      isOpen={isConfirmModalOpen}
      title="一時保存中の案件削除"
      okButtonText="削除"
      okButtonColor="secondary"
      onOKHandler={onOKDeleteProjectDraftConfirmationModal}
      onCloseHandler={onCloseConfirmationModal}
    >
      {`一時保存中の案件【${
        selectedProjectDraftName || '未設定'
      }】を削除しますか？`}
    </ConfirmationModal>
  );

  const getCopyProjectToProjectDraftConfirmationModal = () => (
    <ConfirmationModal
      isOpen={isConfirmModalOpen}
      title="申込済の内容をコピー"
      okButtonText="コピー"
      okButtonColor="primary"
      onOKHandler={onOKCopyProjectToProjectDraftConfirmationModal}
      onCloseHandler={onCloseConfirmationModal}
    >
      {`キャンペーン名【${selectedProjectName}】の内容をコピーし、新たなキャンペーンの申し込みができます。コピーしますか？`}
    </ConfirmationModal>
  );

  return projectDraftSyncStatus !== ProjectDraftSyncStatuses.INITIAL ? null : (
    <Grid container className={classes.root} alignItems="center">
      {generalAnnouncements.map(generalAnnouncement => (
        <MaintenanceMessage
          message={generalAnnouncement.title}
          linkUrl={generalAnnouncement.url}
          key={generalAnnouncement.urlCode}
        />
      ))}
      <UnapprovedMessage inBusiness={distributionPartner.inBusiness} />
      <ApplyStartContainer />
      {projectDraftsForDisplay.length !== 0 ? (
        <>
          <Grid
            item
            container
            justifyContent="flex-start"
            alignItems="flex-end"
            xs={12}
            className={classes.subTitle}
          >
            <Typography variant="h6" color="textPrimary">
              一時保存中の案件
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={12}
            className={classes.projects}
            data-cy="projectDrafts"
          >
            {projectDraftsForDisplay.map((projectDraftForDisplay, index) => (
              <Grid
                item
                className={classes.projectGrid}
                key={`projectDraft-${projectDraftForDisplay.urlCode}`}
              >
                <DashboardProjectDraft
                  projectDraft={projectDraftForDisplay}
                  transitionTimeout={getTransitionTimeout(index)}
                  onClickHandler={() =>
                    goProjectDraft(navigate, projectDraftForDisplay)
                  }
                  onDeleteHandler={() =>
                    onClickDeleteProjectDraft(
                      projectDraftForDisplay.urlCode,
                      projectDraftForDisplay.projectJson.name
                    )
                  }
                />
              </Grid>
            ))}
          </Grid>
        </>
      ) : null}
      <Grid
        item
        container
        justifyContent="flex-start"
        alignItems="flex-end"
        xs={12}
        className={classes.subTitle}
      >
        <Typography variant="h6" color="textPrimary">
          申込済の案件
        </Typography>
      </Grid>
      <Grid
        item
        container
        xs={12}
        className={classes.projects}
        data-cy="projects"
      >
        {projectsForDisplay.length !== 0 ? (
          projectsForDisplay.map((project, index) => (
            <DashboardProject
              project={project}
              index={index}
              goMessageThread={goMessageThread}
              goProject={goProject}
              onClickCopyButtonHandler={() =>
                onClickCopyProjectToProjectDraft(project.urlCode, project.name)
              }
              key={`project-${project.name}`}
            />
          ))
        ) : (
          <ComponentLoader isLoading={isProjectCardLoading} />
        )}
      </Grid>
      {distributionPartner.inBusiness ? (
        <Fragment>
          <Grid
            item
            container
            justifyContent="flex-start"
            alignItems="flex-end"
            xs={12}
            className={classes.subTitle}
          >
            <Typography variant="h6" color="textPrimary">
              APIテスト案件
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={12}
            className={classes.projects}
            data-cy="apiTestProjects"
          >
            <Grid item className={classes.projectGrid}>
              <Card
                className={classes.card}
                onClick={onClickCreateApiTestProject}
              >
                <CardActionArea
                  className={classes.createApiTestProject}
                  data-cy="apiTestProject"
                >
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <AddIcon
                      fontSize="large"
                      className={classes.createApiTestProjectIcon}
                    />
                    <Typography
                      variant="h6"
                      className={classes.createApiTestProjectTitle}
                    >
                      テスト案件を作成
                    </Typography>
                  </Grid>
                </CardActionArea>
              </Card>
            </Grid>
            {sampleProjectsForDisplay.length !== 0
              ? sampleProjectsForDisplay.map((project, index) => (
                  <DashboardProject
                    project={project}
                    index={index}
                    goMessageThread={goMessageThread}
                    goProject={goProject}
                    key={`sampleProject-${project.name}`}
                  />
                ))
              : null}
          </Grid>
        </Fragment>
      ) : null}
      {getConfirmationModal()}
    </Grid>
  );
};

export default withStyles(styles)(DashboardIndex);
