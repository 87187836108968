import {
  selectProjectDraft,
  initilizeProjectDraftSyncStatus
} from '@console/actions/actions';
import { excludeInvalidProjectDraftDataThunk } from '@console/actions/thunks/excludeInvalidProjectDraftData';
import { getProjectDraftsThunk } from '@console/actions/thunks/getProjectDrafts';
import { getProjectDraftsContentsThunk } from '@console/actions/thunks/getProjectDraftsContents';
import { updateProjectDraftThunk } from '@console/actions/thunks/updateProjectDraft';
import Edit from '@console/components/projectDraft/Edit';
import { ProjectDraftSyncStatuses } from '@console/reducers/projectDraftSyncStatus';
import { connect } from 'react-redux';
import type {
  AppThunkAction,
  AppThunkDispatch
} from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';
import type { NavigateFunction } from 'react-router';

// TODO: URLCODEで１件取得が可能になったら、全件取得ではなくそちらのAPIに変更
const getProjectDrafts =
  (
    urlCode: string,
    navigate: NavigateFunction
  ): AppThunkAction<Promise<void>> =>
  (dispatch, getState) =>
    dispatch(getProjectDraftsThunk())
      .then(() => dispatch(getProjectDraftsContentsThunk()))
      .then(() => {
        const selectedProjectDraft = getState().projectDrafts.find(
          projectDraft => projectDraft.urlCode === urlCode
        );

        if (selectedProjectDraft) {
          dispatch(selectProjectDraft(selectedProjectDraft));
        } else {
          navigate('/console/');
        }
        return Promise.resolve();
      })
      .catch(error => {
        console.debug('ERROR');
        return Promise.reject(error);
      });

const updateProjectDraftIfNeeded =
  (): AppThunkAction<Promise<void>> => (dispatch, getState) => {
    if (
      getState().projectDraftSyncStatus === ProjectDraftSyncStatuses.NOT_SYNCED
    ) {
      return dispatch(updateProjectDraftThunk()).catch(() => {});
    }
    return Promise.resolve();
  };

const excludeInvalidProjectDraftData =
  (): AppThunkAction<Promise<void>> => async (dispatch, getState) => {
    dispatch(excludeInvalidProjectDraftDataThunk());
  };

const mapStateToProps = (state: AppState) => ({
  urlCode: state.selectedProjectDraft.urlCode,
  projectDrafts: state.projectDrafts,
  giftConfigs: state.selectedProjectDraft.projectJson.giftConfigs
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  getProjectDrafts: (urlCode: string, navigate: NavigateFunction) =>
    dispatch(getProjectDrafts(urlCode, navigate)),
  updateProjectDraftIfNeeded: () => dispatch(updateProjectDraftIfNeeded()),
  initilizeProjectDraftSyncStatus: () =>
    dispatch(initilizeProjectDraftSyncStatus()),
  excludeInvalidProjectDraftData: () =>
    dispatch(excludeInvalidProjectDraftData())
});

export type EditPropsMappedFromState = ReturnType<typeof mapStateToProps>;
export type EditPropsMappedFromDispatch = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
