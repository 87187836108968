import { ERROR_MESSAGE_FOR_PAGE_VALIDATION } from '@common/middleware/errorMessages';
import {
  setSelectedGiftConfig,
  addNewGiftConfig,
  selectNewGiftConfig,
  deleteGiftConfig,
  initilizeSelectedGiftConfigIndex,
  validateProjectDraftFailure
} from '@console/actions/actions';
import { getGifteeBoxTemplatesThunk } from '@console/actions/thunks/getGifteeBoxTemplates';
import { getSelectedProjectDraftContentsThunk } from '@console/actions/thunks/getSelectedProjectDraftContents';
import { validateGiftConfigsThunk } from '@console/actions/thunks/validateProjectDraft/validateGiftConfigs';
import { GIFTEE_BOX_TEMPLATE_BRAND_NAME } from '@console/common/constants';
import { getActiveStep } from '@console/common/getActiveStep';
import {
  isChoosableGiftConfig,
  isGifteeBoxGiftConfig
} from '@console/common/projectDraft/giftConfig';
import GiftConfigs from '@console/components/projectDraft/GiftConfigs';
import {
  ParentSteps,
  ConfirmationChildSteps
} from '@console/components/projectDraft/Root';
import {
  isBulkSolutionSystemSelected,
  isGcpSolutionSystemSelected,
  isApiSolutionSystemSelected,
  getSelectedContents,
  getGiftCardGiftConfigPrice,
  getGifteeBoxGiftConfigPrice
} from '@console/selectors/getSelectedProjectDraftForDisplay';
import coralAsterisk from 'images/coralAsterisk.svg';
import logo from 'images/logo.png';
import { connect } from 'react-redux';
import { goNextStep } from './StepHandlerContainer';
import type { Contents } from '@common/api/graphql/getContentsGql';
import type {
  AppThunkAction,
  AppThunkDispatch
} from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';
import type {
  GiftConfig,
  GifteeBoxGiftConfig,
  GiftCardGiftConfig
} from '@console/reducers/projectJson/giftConfigs';
import type { NavigateFunction, Location } from 'react-router';

type ContentForDisplay = {
  name: string;
  imageUrl: string;
  brandName: string;
};

export type ContentsForDisplay = ContentForDisplay[];

export type GiftConfigForDisplay = GiftConfig & {
  firstBrandName: string;
  firstContentName: string;
  thumbnailImageUrl: string;
  price: number;
};

type GiftConfigsForDisplay = GiftConfigForDisplay[];

const getFirstBrandName = (
  contents: Contents,
  giftConfig: GiftConfig
): string => {
  if (isChoosableGiftConfig(giftConfig)) {
    return '';
  }
  return contents[0] ? contents[0].brand.name : '';
};

const getFirstContentName = (
  contents: Contents,
  giftConfig: GiftConfig
): string => {
  if (isChoosableGiftConfig(giftConfig)) {
    return '選べるギフト';
  }
  return contents[0] ? contents[0].internalName : '';
};

const getThumbnailImageUrl = (
  contents: Contents,
  giftConfig: GiftConfig
): string => {
  if (isChoosableGiftConfig(giftConfig)) {
    return coralAsterisk;
  }
  return contents[0] ? contents[0].imageUrl : logo;
};

const getGiftConfigsForDisplay = (
  giftConfigs: GiftConfig[],
  contents: Contents
): GiftConfigsForDisplay =>
  giftConfigs.map(giftConfig => {
    if (isGifteeBoxGiftConfig(giftConfig)) {
      return getGifteeBoxGiftConfigForDisplay(giftConfig);
    } else {
      return getGiftCardGiftConfigForDisplay(giftConfig, contents);
    }
  });

const getGifteeBoxGiftConfigForDisplay = (
  gifteeBoxGiftConfig: GifteeBoxGiftConfig
): GiftConfigForDisplay => ({
  ...gifteeBoxGiftConfig,
  firstBrandName: GIFTEE_BOX_TEMPLATE_BRAND_NAME,
  firstContentName: gifteeBoxGiftConfig.selectedGifteeBoxTemplate?.name || '',
  thumbnailImageUrl:
    gifteeBoxGiftConfig.selectedGifteeBoxTemplate?.imageUrl || '',
  price: getGifteeBoxGiftConfigPrice(gifteeBoxGiftConfig.gifteeBoxInitialPoint)
});

const getGiftCardGiftConfigForDisplay = (
  giftCardGiftConfig: GiftCardGiftConfig,
  contents: Contents
): GiftConfigForDisplay => {
  const selectedContentsForDisplay = getSelectedContents(
    contents,
    giftCardGiftConfig.selectedContents
  );

  return {
    ...giftCardGiftConfig,
    firstBrandName: getFirstBrandName(
      selectedContentsForDisplay,
      giftCardGiftConfig
    ),
    firstContentName: getFirstContentName(
      selectedContentsForDisplay,
      giftCardGiftConfig
    ),
    thumbnailImageUrl: getThumbnailImageUrl(
      selectedContentsForDisplay,
      giftCardGiftConfig
    ),
    price: getGiftCardGiftConfigPrice(selectedContentsForDisplay)
  };
};

const onMount = (): AppThunkAction<void> => dispatch => {
  dispatch(getSelectedProjectDraftContentsThunk()).catch(() => {});
  dispatch(getGifteeBoxTemplatesThunk()).catch(() => {});
};

const createGiftConfig =
  (navigate: NavigateFunction, location: Location): AppThunkAction<void> =>
  async dispatch => {
    await dispatch(addNewGiftConfig());
    await dispatch(selectNewGiftConfig());
    goGiftConfig(navigate, location);
  };

const selectGiftConfig =
  (
    navigate: NavigateFunction,
    location: Location,
    index: number
  ): AppThunkAction<void> =>
  dispatch => {
    dispatch(setSelectedGiftConfig(index));
    goGiftConfig(navigate, location);
  };

const goGiftConfig = (navigate: NavigateFunction, location: Location): void => {
  const { parentStep, childStep } = getActiveStep();
  goNextStep(navigate, parentStep, childStep);
};

const handleStepNextFromParent =
  (navigate: NavigateFunction, location: Location): AppThunkAction<void> =>
  async (dispatch, getState) =>
    dispatch(validateGiftConfigsThunk())
      .then(() => {
        navigate(
          `${location.pathname}?parentStep=${ParentSteps.CONFIRMATION}&childStep=${ConfirmationChildSteps.CONFIRMATION}`
        );
      })
      .catch(() => {
        dispatch(
          validateProjectDraftFailure(ERROR_MESSAGE_FOR_PAGE_VALIDATION)
        );
      });

const onConfirmedDeletion =
  (index: number): AppThunkAction<void> =>
  async dispatch => {
    await dispatch(initilizeSelectedGiftConfigIndex());
    await dispatch(deleteGiftConfig(index));
  };

const mapStateToProps = (state: AppState) => ({
  giftConfigsForDisplay: getGiftConfigsForDisplay(
    state.selectedProjectDraft.projectJson.giftConfigs,
    state.contents
  ),
  isBulkSolutionSystemSelected: isBulkSolutionSystemSelected(state),
  isGcpSolutionSystemSelected: isGcpSolutionSystemSelected(state),
  isApiSolutionSystemSelected: isApiSolutionSystemSelected(state),
  errors: state.errors
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  onMount: () => dispatch(onMount()),
  createGiftConfig: (navigate: NavigateFunction, location: Location) =>
    dispatch(createGiftConfig(navigate, location)),
  selectGiftConfig: (
    navigate: NavigateFunction,
    location: Location,
    index: number
  ) => dispatch(selectGiftConfig(navigate, location, index)),
  handleStepNextFromParent: (navigate: NavigateFunction, location: Location) =>
    dispatch(handleStepNextFromParent(navigate, location)),
  onConfirmedDeletion: (index: number) => {
    dispatch(onConfirmedDeletion(index));
  }
});

export type GiftConfigsPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type GiftConfigsPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(GiftConfigs);
