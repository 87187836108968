import {} from 'react-router';

import {
  MAX_CHOOSABLE_GIFT_CONTENTS_STRING,
  GIFTEE_BOX_DISPLAY_NAME
} from '@console/common/constants';
import SectionTitle from '@console/components/projectDraft/common/SectionTitle';
import { TICKET_TYPE } from '@console/reducers/projectJson/giftConfigs';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import StepHandlerContainer from '../../containers/projectDraft/StepHandlerContainer';
import Note from './Note';
import GiftTicketTypeLabel from './giftTicketTypeConfig/GiftTicketTypeLabel';
import type {
  GiftTicketTypeConfigPropsMappedFromState,
  GiftTicketTypeConfigPropsMappedFromDispatch
} from '../../containers/projectDraft/GiftTicketTypeConfigContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    title: {
      marginTop: theme.spacing(3)
    },
    input: {
      padding: `1% 0`,
      paddingRight: `3%`
    },
    button: {
      margin: `${theme.spacing(3)} 0`,
      padding: theme.spacing(1)
    },
    radioLabel: {
      margin: `${theme.spacing()} 0`
    },
    note: {
      marginTop: theme.spacing()
    }
  });

type GiftTicketTypeConfigProps = GiftTicketTypeConfigPropsMappedFromState &
  GiftTicketTypeConfigPropsMappedFromDispatch &
  WithStyles<typeof styles>;

const GiftTicketTypeConfig: React.FC<GiftTicketTypeConfigProps> = ({
  ticketType,
  bulkSolutionSystem,
  apiSolutionSystem,
  onChangeTicketType,
  classes
}) => (
  <Grid
    container
    justifyContent="flex-start"
    alignItems="flex-start"
    className={classes.root}
  >
    <Grid item xs={12} className={classes.title}>
      <SectionTitle text="ギフトのタイプを選択してください" />
    </Grid>

    <Grid item xs={12} className={classes.input}>
      <RadioGroup
        aria-label="ticketType"
        name="ticketType"
        className={classes.group}
        onChange={onChangeTicketType}
        value={ticketType}
      >
        <FormControlLabel
          value={TICKET_TYPE.FIXED}
          control={<Radio color="primary" />}
          label={
            <GiftTicketTypeLabel
              title="単一ギフト"
              description="弊社ラインナップの中から貴社が指定するギフトをお客さまに贈る形式です。ギフトURLの受取手は、指定されたギフトのみを受け取ります。"
              helpLinkTitle="単一ギフトの詳細はこちら"
              helpLinkUrl="https://docs.giftee.biz/business/downloads/image_single-gift.pdf"
            />
          }
          className={classes.radioLabel}
          data-cy="fixedTicketType"
        />
        <FormControlLabel
          value={TICKET_TYPE.CHOOSABLE}
          control={<Radio color="primary" />}
          label={
            <GiftTicketTypeLabel
              title="選べるギフト"
              description={`弊社ラインナップの中から貴社が指定する最大${MAX_CHOOSABLE_GIFT_CONTENTS_STRING}種の商品で構成するギフトURLをお客様に贈る形式です。ギフトURLの受取手は、指定された最大${MAX_CHOOSABLE_GIFT_CONTENTS_STRING}種の商品の中からお好きな商品をひとつ選ぶことができます。`}
              helpLinkTitle="選べるギフトの詳細はこちら"
              availablePeriod="１ヶ⽉後の⽉末"
              helpLinkUrl="https://docs.giftee.biz/business/downloads/image_select-gift.pdf"
            />
          }
          className={classes.radioLabel}
          data-cy="choosableTicketType"
        />
        <FormControlLabel
          value={TICKET_TYPE.GIFTEE_BOX}
          control={<Radio color="primary" />}
          label={
            <GiftTicketTypeLabel
              title={GIFTEE_BOX_DISPLAY_NAME}
              description="貴社指定のポイント内で、豊富なラインナップの中から、ギフトURLの受取手が好きな商品を選べるギフトです。"
              helpLinkTitle={`${GIFTEE_BOX_DISPLAY_NAME}の詳細はこちら`}
              availablePeriod="3ヶ⽉後の⽉末"
              helpLinkUrl="https://docs.giftee.biz/business/downloads/gift_giftee-box.pdf"
            />
          }
          className={classes.radioLabel}
          data-cy="gifteeBoxType"
        />
      </RadioGroup>
    </Grid>
    <StepHandlerContainer />
  </Grid>
);

export default withStyles(styles)(GiftTicketTypeConfig);
