import { addProjectDraft } from '@console/actions/actions';
import { registerProjectDraftThunk } from '@console/actions/thunks/registerProjectDraft';
import ApplyStart from '@console/components/dashboard/index/ApplyStart';
import {
  CampaignOverviewChildSteps,
  ParentSteps
} from '@console/components/projectDraft/Root';
import { connect } from 'react-redux';
import type {
  AppThunkAction,
  AppThunkDispatch
} from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';
import type { NavigateFunction } from 'react-router';

export const addProject =
  (navigate: NavigateFunction): AppThunkAction<void> =>
  async (dispatch, getState) => {
    dispatch(addProjectDraft());

    dispatch(registerProjectDraftThunk())
      .then(() => {
        const { urlCode } = getState().selectedProjectDraft;
        goProjectDraftPage(navigate, urlCode);
      })
      .catch(() => {});
  };

const goProjectDraftPage = (
  navigate: NavigateFunction,
  projectDraftUrlCode: UrlCode
) =>
  navigate(
    `/console/project_drafts/${projectDraftUrlCode}/edit?parentStep=${ParentSteps.CAMPAIGN_OVERVIEW}&childStep=${CampaignOverviewChildSteps.CAMPAIGN_OVERVIEW}`
  );

const mapStateToProps = (state: AppState) => ({
  inBusiness: state.distributionPartner.inBusiness
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  addProject: (navigate: NavigateFunction) => dispatch(addProject(navigate))
});

export type ApplyStartPropsMappedFromState = ReturnType<typeof mapStateToProps>;
export type ApplyStartPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(ApplyStart);
