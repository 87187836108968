import { ERROR_MESSAGE_FOR_PAGE_VALIDATION } from '@common/middleware/errorMessages';
import {
  changeContentsBrandFilter,
  changeContentsPriceRangeFilter,
  validateProjectDraftFailure
} from '@console/actions/actions';
import { getContentsOfSelectedProjectDraftThunk } from '@console/actions/thunks/getContentsOfSelectedProjectDraft';
import { getContinuousContentsThunk } from '@console/actions/thunks/getContinuousContents';
import { getInitialContentsThunk } from '@console/actions/thunks/getInitialContents';
import { toggleFixedContentThunk } from '@console/actions/thunks/handleContentSelection';
import { validateFixedContentSelectionThunk } from '@console/actions/thunks/validateProjectDraft/validateGiftConfig/validateContentsSelection';
import { getActiveStep } from '@console/common/getActiveStep';
import FixedContentSelection, {
  SLIDER_MAX,
  SLIDER_MIN
} from '@console/components/common/contentsSelection/FixedContentSelection';
import { getContentsWithIsSelectedInSelectedGiftConfigInSelectedProjectDraft } from '@console/selectors/getSelectedProjectDraftForDisplay';
import { connect } from 'react-redux';
import { goNextStep } from '../StepHandlerContainer';
import type { Brand } from '@common/api/graphql/getBrandsGql';
import type { Content } from '@common/api/graphql/getContentsGql';
import type {
  AppThunkAction,
  AppThunkDispatch
} from '@console/actions/thunkType';
import type { SliderPrices } from '@console/components/common/PricesSlider';
import type { AppState } from '@console/reducers';
import type { NavigateFunction } from 'react-router';

const handleStepNextFromParent =
  (navigate: NavigateFunction): AppThunkAction<Promise<void>> =>
  dispatch =>
    dispatch(validateFixedContentSelectionThunk())
      .then(() => {
        const { parentStep, childStep } = getActiveStep();
        goNextStep(navigate, parentStep, childStep);
      })
      .catch(() => {
        dispatch(
          validateProjectDraftFailure(ERROR_MESSAGE_FOR_PAGE_VALIDATION)
        );
      });

const onChangeBrandFilter =
  (brand: Brand | null): AppThunkAction<void> =>
  async dispatch => {
    const brands = brand ? [brand] : undefined;
    dispatch(changeContentsBrandFilter(brands));

    await dispatch(getContentsOfSelectedProjectDraftThunk()).catch(() => {});
    await dispatch(getInitialContentsThunk()).catch(() => {});
  };

const onChangePriceRangeFilter =
  (prices: SliderPrices): AppThunkAction<void> =>
  async dispatch => {
    const minPrice =
      prices.lowerPrice === SLIDER_MIN ? undefined : prices.lowerPrice;
    const maxPrice =
      prices.higherPrice === SLIDER_MAX ? undefined : prices.higherPrice;

    dispatch(changeContentsPriceRangeFilter(minPrice, maxPrice));

    await dispatch(getContentsOfSelectedProjectDraftThunk()).catch(() => {});
    await dispatch(getInitialContentsThunk()).catch(() => {});
  };

const toggleContent =
  (content: Content): AppThunkAction<void> =>
  dispatch => {
    dispatch(toggleFixedContentThunk(content));
  };

const handleScrollToBottom = (): AppThunkAction<void> => dispatch => {
  dispatch(getContinuousContentsThunk()).catch(() => {});
};

const mapStateToProps = (state: AppState) => ({
  brands: state.brands,
  contents:
    getContentsWithIsSelectedInSelectedGiftConfigInSelectedProjectDraft(state),
  contentsTotalCount: state.searchResult.contents.totalCount ?? 0,
  isContentsLoading: state.loaders.isContentsLoading,
  giftConfigError:
    state.errors.projectDraftGiftConfigs[
      state.selectedProjectDraft.projectJson.selectedGiftConfigIndex
    ],
  selectedGiftConfigIndex:
    state.selectedProjectDraft.projectJson.selectedGiftConfigIndex
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  toggleContent: (content: Content) => dispatch(toggleContent(content)),
  handleStepNextFromParent: (navigate: NavigateFunction) =>
    dispatch(handleStepNextFromParent(navigate)),
  onChangeBrandFilter: (brand: Brand | null) =>
    dispatch(onChangeBrandFilter(brand)),
  onChangePriceRangeFilter: (prices: SliderPrices) =>
    dispatch(onChangePriceRangeFilter(prices)),
  handleScrollToBottom: () => dispatch(handleScrollToBottom())
});

export type FixedContentSelectionPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type FixedContentSelectionPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FixedContentSelection);
