import SectionTitle from '@console/components/projectDraft/common/SectionTitle';
import GifteeBoxTemplateContentSelectionsModalContainer from '@console/containers/common/GifteeBoxTemplateContentSelectionsModalContainer';
import StepHandlerContainer from '@console/containers/projectDraft/StepHandlerContainer';
import {
  Typography,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Radio,
  Button,
  Chip
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import type {
  GifteeBoxTemplateSelectionPropsMappedFromDispatch,
  GifteeBoxTemplateSelectionPropsMappedFromState
} from '@console/containers/projectDraft/contentsSelection/GifteeBoxTemplateSelectionContainer';
import type { LocalGifteeBoxTemplate } from '@console/reducers/gifteeBoxTemplates';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';
import type { MouseEvent } from 'react';

const styles = (theme: Theme): StyleRules => {
  const GIFTEE_BOX_TEMPLATE_IMAGE_WIDTH = 96;
  const GIFTEE_BOX_TEMPLATE_IMAGE_HEIGHT = 96;
  return createStyles({
    title: {
      margin: `${theme.spacing(3)} 0`
    },
    tableContainer: {
      overflowX: 'scroll',
      whiteSpace: 'nowrap'
    },
    table: {
      textAlign: 'left',
      marginTop: theme.spacing(0.5)
    },
    tableCell: {
      padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
      verticalAlign: 'middle'
    },
    gifteeBoxTemplateRow: {
      '&:hover': {
        opacity: 0.8
      }
    },
    selectedIcon: {
      width: 40
    },
    gifteeBoxTemplateImage: {
      width: GIFTEE_BOX_TEMPLATE_IMAGE_WIDTH,
      height: GIFTEE_BOX_TEMPLATE_IMAGE_HEIGHT,
      objectFit: 'contain'
    },
    chip: {
      marginBottom: theme.spacing(0.5),
      verticalAlign: 'text-bottom'
    }
  });
};

type GifteeBoxTemplateSelectionProps =
  GifteeBoxTemplateSelectionPropsMappedFromDispatch &
    GifteeBoxTemplateSelectionPropsMappedFromState &
    WithStyles<typeof styles>;

const GifteeBoxTemplateSelection: React.FC<GifteeBoxTemplateSelectionProps> = ({
  classes,
  giftConfigError,
  gifteeBoxTemplates,
  selectedGifteeBoxTemplate,
  handleStepNextFromParent,
  getGifteeBoxTemplates,
  toggleSelectedGifteeBoxTemplate,
  selectGifteeBoxTemplate,
  deselectGifteeBoxTemplate
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    getGifteeBoxTemplates();
  }, []);

  const [
    gifteeBoxTemplateContentSelectionsModalOpen,
    setGifteeBoxTemplateContentSelectionsModalOpen
  ] = useState(false);

  const openGifteeBoxTemplateContentSelectionsModal = (
    event: MouseEvent<HTMLButtonElement>,
    gifteeBoxTemplate: LocalGifteeBoxTemplate
  ) => {
    event.stopPropagation();
    selectGifteeBoxTemplate(gifteeBoxTemplate);
    setGifteeBoxTemplateContentSelectionsModalOpen(true);
  };

  const closeGifteeBoxTemplateContentSelectionsModal = () => {
    setGifteeBoxTemplateContentSelectionsModalOpen(false);
    deselectGifteeBoxTemplate();
  };

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="flex-start"
      className={classes.root}
      data-cy="gifteeBoxTemplateSelection"
    >
      <Typography
        id="commonError"
        className={classes.errorMessage}
        color="error"
      >
        {giftConfigError?.selectedGifteeBoxTemplate}
      </Typography>
      <Grid item xs={12} className={classes.title} data-cy="pageTitle">
        <SectionTitle text="ギフトを１種類選んでください" />
      </Grid>
      <div className={classes.tableContainer} data-cy="tableContainer">
        <Table className={classes.table}>
          <TableHead data-cy="tableHeader">
            <TableRow>
              <TableCell align="center" colSpan={4} />
            </TableRow>
          </TableHead>
          <TableBody>
            {gifteeBoxTemplates.map(gifteeBoxTemplate => (
              <TableRow
                key={`gifteeBoxTemplate-${gifteeBoxTemplate.urlCode}`}
                className={classes.gifteeBoxTemplateRow}
                onClick={() =>
                  toggleSelectedGifteeBoxTemplate(gifteeBoxTemplate)
                }
                data-cy={`gifteeBoxTemplate-${gifteeBoxTemplate.urlCode}`}
              >
                <TableCell
                  className={classes.tableCell}
                  align="center"
                  data-cy={`gifteeBoxTemplateSelected-${gifteeBoxTemplate.urlCode}`}
                >
                  <Radio
                    checked={
                      selectedGifteeBoxTemplate?.urlCode ===
                      gifteeBoxTemplate.urlCode
                    }
                    color="primary"
                    className={classes.selectedIcon}
                  />
                  {/* <div>
                    {selectedGifteeBoxTemplate?.urlCode ===
                    gifteeBoxTemplate.urlCode ? (
                      <CheckCircle color="primary" />
                    ) : null}
                  </div> */}
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  align="left"
                  data-cy={`gifteeBoxTemplateImage-${gifteeBoxTemplate.urlCode}`}
                >
                  <img
                    src={gifteeBoxTemplate.imageUrl}
                    alt={gifteeBoxTemplate.name}
                    className={classes.gifteeBoxTemplateImage}
                  />
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  align="left"
                  data-cy={`gifteeBoxTemplateName-${gifteeBoxTemplate.urlCode}`}
                >
                  {gifteeBoxTemplate.isLimitedPublished && (
                    <Chip
                      label="貴社専用"
                      color="primary"
                      variant="outlined"
                      size="small"
                      className={classes.chip}
                    />
                  )}
                  <Typography variant="body1">
                    {gifteeBoxTemplate.name}
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={event =>
                      openGifteeBoxTemplateContentSelectionsModal(
                        event,
                        gifteeBoxTemplate
                      )
                    }
                    data-cy={`gifteeBoxTemplateContentSelectionButton-${gifteeBoxTemplate.urlCode}`}
                  >
                    掲載商品一覧
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <GifteeBoxTemplateContentSelectionsModalContainer
        isOpen={gifteeBoxTemplateContentSelectionsModalOpen}
        handleClose={closeGifteeBoxTemplateContentSelectionsModal}
      />
      <StepHandlerContainer
        handleStepNextFromParent={() => handleStepNextFromParent(navigate)}
      />
    </Grid>
  );
};

export default withStyles(styles)(GifteeBoxTemplateSelection);
